<template>
    <div class="share-dialog" :class="{open:shareDialogOpen}">
        <close-btn @click="close"></close-btn>
        <ul class="share-options">
            <li v-if="!apartmentOnly || !isApartment">
                <fp-icon-button symbol="share" @click="share">{{$t('שיתוף')}}</fp-icon-button>
            </li>
						<li v-if="!apartmentOnly || !isApartment">
								<fp-icon-button symbol="copy" @click="copyToClipboard">{{$t('העתקה ללוח')}}</fp-icon-button>
						</li>
						<li v-if="!apartmentOnly || !isApartment">
								<a target="_blank" :href="`${whatsappBaseLink}${whatsapp.phone}&text=${whatsapp.text}${whatsappEncodedUrl}`">
										<fp-icon symbol="whatsapp"></fp-icon>
										<span>{{$t('שיתוף ב Whatsapp')}}</span>
								</a>
						</li>
						<li v-if="isApartment">
								<fp-icon-button symbol="share" @click="shareApartmentOnly">
										<span v-if="apartmentOnly">{{$t('שיתוף דירה')}}</span>
										<span v-else>{{$t('שיתוף רק דירה')}}</span>
								</fp-icon-button>
						</li>
						<li v-if="isApartment">
								<fp-icon-button symbol="copy" @click="copyApartmentOnly">
										<span v-if="apartmentOnly">{{$t('העתק ללוח')}}</span>
										<span v-else>{{$t('העתק ללוח לינק דירה')}}</span>
								</fp-icon-button>
						</li>
						<li v-if="isApartment">
								<a target="_blank" :href="`${whatsappBaseLink}${whatsapp.phone}&text=${whatsapp.text}${whatsappEncodedUrlApartmentOnly}`">
										<fp-icon symbol="whatsapp"></fp-icon>
										<span v-if="apartmentOnly">{{$t('שיתוף ב Whatsapp')}}</span>
										<span v-else>{{$t('שיתוף רק דירה ב Whatsapp')}}</span>
								</a>
						</li>
        </ul>
        <p class="clipboard-msg" v-if="urlCopySuccess">{{$t('כתובת הועתקה ללוח')}}</p>
    </div>
</template>

<script>
import CloseBtn from "@/components/buttons/CloseBtn"
import FpIconButton from "@/components/buttons/FpIconButton"
import {mapGetters} from "vuex"
import FpIcon from "@/components/FpIcon"
import copy from 'copy-to-clipboard'

export default {
    name: "ShareDialog",
    components: {FpIcon, FpIconButton, CloseBtn},
    data(){
        return {
            whatsapp: {
                text: '',
                phone: ''
            },
            urlCopySuccess: false
        }
    },
    computed: {
        whatsappEncodedUrl(){
            return encodeURI(this.shareUrl)
        },
				whatsappEncodedUrlApartmentOnly(){
						return encodeURI(`${this.shareUrl}/ao`)
				},
        whatsappBaseLink(){
            if(this.isMobile){
                return "whatsapp://send?phone="
            }
            return "http://api.whatsapp.com/send?phone="
        },
				shareUrl(){
						const baseUrl = `${window.location.protocol}//${window.location.host}`
						if(this.shareDialogType === 'floor'){
								const floorId = this.floorsPageCurrent?.id || this.shareDialogFloor?.id
								return `${baseUrl}/projects/${this.currentProjectId}/${this.shareDialogType}s/${floorId}`
						}
						const apartmentId = this.apartmentsPageCurrent?.id || this.shareDialogApartment?.id
						return `${baseUrl}/projects/${this.currentProjectId}/${this.shareDialogType}s/${apartmentId}`
				},
				isApartment(){
						return this.shareDialogType === 'apartment'
				},
        ...mapGetters(['shareDialogOpen', 'isMobile','apartmentOnly','currentProjectId','apartmentsPageCurrent', 'shareDialogApartment','shareDialogFloor', 'shareDialogType'])
    },
    methods: {
        currentUrl(){
          return window.location.href
        },
        async share(){
            if(typeof(navigator) === 'object' && typeof(navigator.share) === 'function'){
                await navigator.share({
                    url: this.shareUrl
                })
            }
        },
        copyToClipboard(){
            this.urlCopySuccess = copy(this.shareUrl)
            setTimeout(() => {
                this.urlCopySuccess = false
            }, 2000)
        },
        close(){
            this.$store.dispatch('shareDialogToggle', {show: false})
        },
				copyApartmentOnly(){
						this.urlCopySuccess = copy(`${this.shareUrl}/ao`)
						setTimeout(() => {
								this.urlCopySuccess = false
						}, 2000)
				},
				async shareApartmentOnly(){
						if(typeof(navigator) === 'object' && typeof(navigator.share) === 'function'){
								await navigator.share({
										url: `${this.shareUrl}/ao`
								})
						}
				}
    }
}
</script>

<style scoped lang="scss">

    .share-dialog {
        position: fixed;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 98vw;
        padding: 25px;
        border-radius: 4px;
        border: solid 1px #fff;
        display: none;
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.79) 0%, #000000 78.65%, #000000 100%);
        box-shadow: 4px 3px 6px 3px #000000b3;

        &.open {
            display: block;
        }

        :deep(.close-btn){
            position: absolute;
            top: 10px;
            left: 5px;
        }

        .clipboard-msg {
            position: absolute;
            bottom: 10px;
            right: 10px;
            color: #f00075;
        }

        .share-options {
            list-style: none;
            padding: 0;

            li {
                line-height: 1;
                margin-bottom: 1rem;
                white-space: nowrap;

                a {
                    display: flex;
                    align-items: center;
                    gap: .8rem;
                    font-size: 1.125rem;
                }

                button {
                    font-size: 1.125rem;

                    &:hover {
                        color: var(--primary);
                    }
                }
            }
        }
    }
</style>
