<template>
    <div class="flex justify-between w-75">
        <p class="clipboard-msg" v-if="urlCopySuccess">{{$t('כתובת הועתקה ללוח')}}</p>
        <router-link :to="`/projects/privacy`" @click.stop>
            <FpNewIcon iconName="privacy" class="circledIcon bgGray cursor-pointer" size="1rem" />

        </router-link>
        <div class="accessabilityPopper">
            <Popper arrow hover placement="top" >
                <FpNewIcon iconName="accessibility" class="circledIcon bgGray cursor-pointer" size="1rem"
                />
                <template #content>
                    <Accessibility />
                </template>
            </Popper>

        </div>
        <!-- <Accessibility /> -->
        <!-- <Popper arrow hover placement="top" :content="$t('קישור אתר')"> -->
        <FpNewIcon iconName="shareButton2" class="circledIcon bgGray cursor-pointer" size="1rem" @click.stop="copyToClipboard"/>

        <!-- </Popper> -->

        <div class="loginpopper">
            <Popper arrow click placement="top" >
                <FpNewIcon iconName="users" class="circledIcon bgGray cursor-pointer" size="1rem"
                />
                <template #content>
                    <LoginModal />
                </template>
            </Popper>

        </div>
    </div>
</template>

<script>
// import FpIconButton from "@/components/buttons/FpIconButton";
import { mapGetters } from "vuex";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
import Accessibility from "@/components/Accessibility";
import LoginModal from "@/components/LoginModal";
import copy from 'copy-to-clipboard'
import Popper from "vue3-popper";
export default {
    name: "MenuBarLeft",
    props: {
        showLabels: {
            default: false
        }
    },
    data(){
        return {
            urlCopySuccess: false
        }
    },
    components: { 
        FpNewIcon,
        Accessibility,
        LoginModal,
        Popper },
    computed: {
        showProjectsTab() {
            if (this.$store.getters.settings && this.$store.getters.settings.projects && this.$store.getters.settings.projects.show_projects_tab !== undefined) {
                return this.$store.getters.settings.projects.show_projects_tab
            }
            return true
        },
        ...mapGetters(['isMobile'])
    },
    methods: {
        toggleProjectsPane() {
            this.$store.dispatch('toggleProjectsPane')
        },
        toggleAccessibilityModal() {
            this.$store.commit('toggleAccessibilityModal')
        },
        copyToClipboard(){
            const baseUrl = `${window.location.protocol}//${window.location.host}`
            this.urlCopySuccess = copy(baseUrl)
            setTimeout(() => {
                this.urlCopySuccess = false
            }, 2000)
        },
    }
}
</script>

<style scoped lang="scss">
    .accessabilityPopper{
        :deep(.popper) {       
  
            transform: translate(-3.7rem, -47.2031px) !important;
            #arrow {
                transform: translate(11.85rem, 0) !important;
            }
        }

    }
    .loginpopper{
        :deep(.popper) {       
            background: #6149F9;
            width: 15rem;
            transform: translate(-9.3rem, -47.2031px) !important;
            #arrow {
                transform: translate(11.85rem, 0) !important;
                
            }
        }
        :deep(.popper #arrow::before) {
            background: var(--primary);
          }

    }
    .clipboard-msg{
        position: absolute;
        top: 2.8rem;
        font-size: .8rem;
        right: 2rem;
    }

    .ltr {
        .accessabilityPopper{
            :deep(.popper) {       
      
                transform: translate(3.7rem, -47.2031px) !important;
                #arrow {
                    transform: translate(1.1rem, 0) !important;
                }
            }
    
        }
        .loginpopper{
            :deep(.popper) {       

                transform: translate(9.3rem, -47.2031px) !important;
                #arrow {
                    transform: translate(1.1rem, 0) !important;
                    
                }
            }

        }
    }
</style>
