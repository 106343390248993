<template>
  <div class="animatedCircledIcon disable-pointer-events" :style="{
    width: width + 'px',
    height: height + 'px'
  }">
    <svg :width="width" :height="height">
      <defs>
        <filter id="f1" x="0" y="0" width="150%" height="150%">
          <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
          <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
          <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
        </filter>
      </defs>
      <circle ref="circle" :cx="center" :cy="center" :r="radius" :stroke="strokeColor" stroke-width="1" fill="none"
        :stroke-dasharray="circumference" />
      <!-- <g v-if="animate"> -->
      <circle v-for="(numDot) in numTrailDots" :key="numDot" :ref="`dotGlow${numDot}`" :r="calculateRadius(numDot)"
        :fill="animate ? strokeColor : 'none'" :opacity="animate ? calculateOpacity(numDot) : 0" filter="url(#f1)" />

      <!-- </g> -->
      <circle ref="dot" :r="initialDotRadius" :fill="strokeColor" />
    </svg>
  </div>
</template>



<script>
import { gsap } from 'gsap';

export default {
  name: 'AnimatedCircle',
  data() {
    return {
      circumference: Math.PI * this.radius,
      numTrailDots: 20,
      animationState: 'stopped', // can be 'stopped', 'running', 'frozen'
      tl: null, // holds the GSAP timeline instance
    };
  },
  props: {
    animate: {
      type: Boolean,
      required: false,
      default: true,
    },
    radius: {
      type: Number,
      required: false,
      default: 80,
    },
    width: {
      type: Number,
      required: false,
      default: 200,
    },
    height: {
      type: Number,
      required: false,
      default: 200,
    },
    strokeColor: {
      type: String,
      default: "black",
    },
    baseStrokeColor: {
      type: String,
      default: "blue",
    },
    iterations: {
      type: Number,
      default: 1,
    },
    duration: {
      type: Number,
      default: 1000,
    },
    initialDotRadius: {
      type: Number,
      default: 3,
    },
    startOffset: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      required: false,
      default: false
    },
    hoverMode: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    center() {
      return this.width / 2;
    },
  },
  mounted() {
    this.setInitialDotPositions();
    // if (this.animate) {
      setTimeout(() => {
        this.startAnimation();
        if (!this.animate) {
          setTimeout(() => {
            this.freezeAnimation()
          }, this.startOffset);
          }
        }, this.startOffset);

    // }

  },
  watch: {
    animate(newVal) {
      if (newVal) this.startAnimation();
      else this.freezeAnimation()
    }
  },
  methods: {
    setInitialDotPositions() {
      const mainDot = this.$refs.dot;
      this.updateDot(mainDot, 0);

      for (let index = 0; index < this.numTrailDots; index++) {
        const dot = this.$refs[`dotGlow${index + 1}`];
        this.updateDot(dot, -index / 100); // Adjust the division value to set a proper initial position for trail dots
      }
    },
    calculateRadius(index) {
      return this.initialDotRadius - (index / this.numTrailDots) * this.initialDotRadius;
    },
    calculateOpacity(index) {
      return 1 - (index / this.numTrailDots);
    },
    drawCircle() {
      if(!this.$refs.circle){
        return;
      }
      const circle = this.$refs.circle;
      const dot = this.$refs.dot;
      gsap.set(circle, {
        strokeDasharray: this.circumference,
        strokeDashoffset: -this.circumference
      });

      const tl = gsap.timeline({ repeat: -1 });

      tl.to(circle, { strokeDashoffset: 0, duration: 2, ease: 'none', onUpdate: () => this.updateDot(dot, tl.progress() * 2) })
        .to(circle, { strokeDashoffset: -this.circumference, duration: 2, ease: 'none', immediateRender: false, onUpdate: () => this.updateDot(dot, tl.progress() * 2) });

      for (let index = 0; index < this.numTrailDots; index++) {
        const dot = this.$refs[`dotGlow${index + 1}`]
        gsap.to(dot, { onUpdate: () => this.updateDot(dot, tl.progress() * 2 - (index / 200)), repeat: -1 });

      }
      this.tl = tl
    },
    updateDot(dot, progress) {
      const angle = 2 * Math.PI * progress;
      const x = this.center + this.radius * Math.cos(angle);
      const y = this.center + this.radius * Math.sin(angle);
      gsap.set(dot, { attr: { cx: x, cy: y } });
    },
    startAnimation() {
      try{
        if (this.animationState === 'stopped') {
          this.drawCircle();
          this.animationState = 'running';
        } else if (this.animationState === 'frozen') {
          this.tl.resume();
          this.animationState = 'running';
        }
      }catch (e) {
        // nothing
      }
    },
    resetAnimation() {
      if (this.tl) {
        this.tl.kill();
        this.tl = null;
        this.animationState = 'stopped';
      }
    },
    freezeAnimation() {
      if (this.tl && this.animationState === 'running') {
        this.tl.pause();
        this.animationState = 'frozen';
      }
    },
    continueAnimation() {
      if (this.tl && this.animationState === 'frozen') {
        this.tl.resume();
        this.animationState = 'running';
      }
    },
  }

};
</script>
