<template>
    <transition>
        <div class="projects-pane" v-if="open">
            <close-btn @click.stop="toggle"></close-btn>
            <div class="projects-pane-header">{{$t('פרוייקטים')}}</div>
            <perfect-scrollbar>
                <div class="project" :class="{clickable: proj.status.clickable}" v-for="(proj, index) in projects" :key="index" @click="selectProject(proj)">
                    <template v-if="proj.status.showed">
                        <div class="thumbnail" v-if="proj.thumbnail">
                            <img :src="proj.thumbnail" :alt="proj.name">
                        </div>
                        <span class="project-name">{{proj.name}}</span>
                        <span class="project-address" v-if="proj.address"> | {{proj.address}}</span>
                    </template>
                </div>
            </perfect-scrollbar>
        </div>
    </transition>
    <div class="pane-toggle-tab" v-if="showProjectsTab" @click="toggle">
        <fp-icon-button symbol="caret-right-circle">{{$t('פרוייקטים')}}</fp-icon-button>
    </div>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import CloseBtn from "@/components/buttons/CloseBtn";
import {mapGetters} from "vuex";

export default {
    name: "ProjectsPane",
    components: {CloseBtn, FpIconButton},
    computed: {
        open: {
            get(){
                return this.$store.getters.projectsPaneOpen
            },
            set(value){
                this.$store.commit('projectsPaneOpen', value)
                if(value){
                    this.$store.commit('projectModal', false)
                }
            }
        },
        showProjectsTab(){
            if(this.$store.getters.settings && this.$store.getters.settings.projects && this.$store.getters.settings.projects.show_projects_tab !== undefined){
                return this.$store.getters.settings.projects.show_projects_tab
            }
            return true
        },
        ...mapGetters(['projects'])
    },
    methods: {
        toggle(){
            this.open = !this.open

            if(!this.open && this.$store.state.currentProjectId){
                this.$store.commit('projectModal', true)
            }
        },
        close(){
            this.open = false
            if(this.$store.state.currentProjectId){
                this.$store.commit('projectModal', true)
            }
        },
        selectProject(project){
            if(project.status.clickable){
                this.$router.push(`/projects/${project.id}`).then(() => {
                    this.close()
                    this.$store.commit('projectModal', true)
                })
            }
        }
    },
    mounted() {

    }
}
</script>
<style scoped lang="scss">
    .pane-toggle-tab {
        border-radius: 0 0 5px 5px;
        background-color: rgba(0, 0, 0, 0.70);
        padding: 10px 10px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
    }

    .projects-pane {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 340px;
        max-width: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
        border: 1px solid #FFFFFF;
        box-sizing: border-box;
        border-radius: 5px;
        z-index: 10;
        margin-bottom: 5.1875rem;
        display: flex;
        flex-flow: column;
        padding: 1.875rem 0;

        &.v-enter-active,
        &.v-leave-active {
            transition: transform 1s ease, opacity 1s ease;
        }

        &.v-enter-from {
            transform: translateX(500px);
        }
        &.v-leave-to {
            opacity: 0;
        }

        > div {
            padding: 0 1.875rem;
        }

        .projects-pane-header {
            margin-bottom: 20px;
            font-weight: 700;
            padding: 0 1.875rem;
        }

        &.init-hidden {
            display: none;
        }

        .close-btn {
            position: absolute;
            top: 30px;
            left: 30px;
        }

        .project {
            margin-bottom: 1.125rem;

            &.clickable {
                cursor: pointer;
            }

            .thumbnail {
                margin-bottom: 0.625rem;

                img {
                    max-width: 100%;
                }
            }

        }
    }

    .is-mobile {
        .projects-pane {
            border: none;
            border-radius: 0;
            margin-top: 57px;
            width: 100%;
            margin-bottom: 3.75rem;
        }
        .pane-toggle-tab {
            display: none;
        }
    }

		.fp-side-menu-wrapper + .fp-app-wrapper {
				.pane-toggle-tab {
						right: auto;
						left: 0;
						top: auto;
						bottom: 0;
						backdrop-filter: blur(5px);
						border-radius: 0;
				}

				.projects-pane {
						border: none;
						border-radius: 0;
						height: 100svh;
						right: auto;
						left: 0;
						backdrop-filter: blur(5px);

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(-500px);
						}
				}
		}

		.ltr {
				.projects-pane {
						.close-btn {
								left: auto;
								right: 30px;
						}
				}

				.fp-side-menu-wrapper + .fp-app-wrapper {
						.pane-toggle-tab {
								left: auto;
								right: 0;
						}

						.projects-pane {
								right: 0;
								left: auto;

								&.v-enter-from,
								&.v-leave-to {
										transform: translateX(500px);
								}
						}
				}
		}

</style>
