import ElementsPage from "@/pages/ElementsPage.vue";
import NewProjectPage from "@/pages/NewProjectPage.vue";
import MainPage from "@/pages/MainPage.vue";
import AdminPage from "@/pages/AdminPage.vue";
import LoginPage from "@/pages/admin/LoginPage.vue";
import ApartmentsAdmin from "@/pages/admin/ApartmentsAdmin.vue";
import EntrepreneursPage from "@/pages/EntrepreneursPage.vue";
import AreaPage from "@/pages/AreaPage.vue";
import ApartmentPage from "@/pages/ApartmentPage.vue";
import ContentPage from "@/pages/ContentPage.vue";
import ApartmentsComparePage from "@/pages/ApartmentsComparePage.vue";
import ScrollGalleryPage from "@/pages/ScrollGalleryPage.vue";
import FavoriteApartments from "@/pages/FavoriteApartments.vue";
import store from "@/store";
import FloorPage from "@/pages/FloorPage.vue";
import FloorsPage from "@/pages/FloorsPage.vue";
import ProjectsAdmin from "@/pages/admin/ProjectsAdmin.vue";
import BuildingsAdmin from "@/pages/admin/BuildingsAdmin.vue";
import MapBoxAdmin from "@/pages/admin/MapBoxAdmin.vue";
// import ApartmentsPage from "@/pages/ApartmentsPage.vue";
// import MapsManager from "@/pages/MapsManager.vue";

import PrivacyAgreement from "@/components/PrivacyAgreement.vue"
import ModelApartmentsPage from "@/model-pages/ModelApartmentsPage.vue";
import TabsContentPage from "@/pages/TabsContentPage.vue";

export default [
    {
        path: '/',
        name: 'home',
        component: MainPage
    },
    {
        path: '/view-360',
        name: 'view-360',
        component: MainPage
    },
    {
        path: '/elements',
        component: ElementsPage
    },
    {
        path: '/content/:postName',
        name: 'content',
        component: ContentPage
    },
    {
        path: '/projects/:projectId',
        name: 'projects',
        component: NewProjectPage
    },
    {
        path: '/projects/:projectId/apartments',
        name: 'apartments',
        component: ModelApartmentsPage
    },
    {
        path: '/projects/:projectId/apartments-compare',
        name: 'apartments-compare',
        component: ApartmentsComparePage
    },
    {
        path: '/projects/:projectId/apartments/:apartmentId',
        name: 'apartment',
        component: ApartmentPage
    },
    {
        path: '/projects/:projectId/apartments/:apartmentId/ao',
        name: 'apartment-only',
        component: ApartmentPage
    },
    {
        path: '/projects/:projectId/floors',
        name: 'floors',
        component: FloorsPage
    },
    {
        path: '/projects/:projectId/floors/:floorId',
        name: 'floor',
        component: FloorPage
    },
    {
        path: '/projects/:projectId/entrepreneurs-architects',
        component: EntrepreneursPage
    },
    {
        path: '/projects/:projectId/favorites',
        name: 'favorite-apartments',
        component: FavoriteApartments
    },
    {
        path: '/projects/:projectId/:postId',
        component: EntrepreneursPage
    },
    {
        path: '/projects/:projectId/content/:postId',
        component: EntrepreneursPage
    },
    {
        path: '/projects/:projectId/tcontent/:postId',
        component: TabsContentPage
    },
    {
        path: '/projects/:projectId/scroll-gallery/:postId',
        component: ScrollGalleryPage
    },
    {
        path: '/projects/:projectId/area',
        component: AreaPage
    },
    {
        path: '/projects/:projectId/area/:postId',
        name: 'area-page',
        component: AreaPage
    },
    {
        path: '/projects/privacy',
        name: 'privacy',
        component: PrivacyAgreement
    },
    {
        path: '/fp-admin',
        component: AdminPage,
        children: [
            {
                path: 'login',
                name: 'login',
                component: LoginPage
            },
            {
                path: 'projects',
                component: ProjectsAdmin
            },
            {
                path: 'apartments',
                component: ApartmentsAdmin
            },
            {
                path: 'buildings',
                component: BuildingsAdmin
            },
            {
                path: 'mapbox',
                component: MapBoxAdmin
            },
            {
                path: 'logout',
                name: 'logout',
                component: {
                    async beforeRouteEnter() {
                        await store.dispatch("logout")
                    }
                }
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/'
    }
];
