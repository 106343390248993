<template>
  <div class="wrapper">
    <div class="selectVila">
      <div class="dropDowns">
        <fp-custom-drop-down :value="getDropDownLabelByValue(selectedVila, vilaTypes)" name="Vila type" :options="vilaTypes"
          :empty-option="true" @change="setSelectedVila" />
        <fp-custom-drop-down :value="getDropDownLabelByValue(selectedTerm, termTypes)" name="Term" :options="termTypes"
          :empty-option="true" @change="setSelectedTerm" />

      </div>
      <!-- <select v-model="selectedVila">
        <option value="3">3-Rooms</option>
        <option value="4">4-Rooms</option>

      </select>
      <select v-model="selectedTerm">
        <option value="shortTerm">Short term</option>
        <option value="longTerm">Long term</option>

      </select> -->


      <div class="middleSection">
        <h1 class="ltr">Investment {{ formatNum(initialInvestment[selectedVila]) }} &#3647;</h1>
        <div class="potentialButtons">
          <button :onClick="() => setPotential('Good')">
            Good
          </button>
          <button :onClick="() => setPotential('Bad')">
            Bad
          </button>
        </div>
        <div class="inputs">
          <div v-if="selectedTerm == 'shortTerm'">
            <div class="headers">

              <div>(%) Occupancy </div>
              <div>Daily rental rate</div>
            </div>

            <div v-for="(season, index) in seasons" :key="index" class="flex between align-center inputRow">

              <FpSlider :id="'occupancySlider' + index" v-model="occupancies[index]" :min="50" :max="100"
                 />
              <FpSlider :id="'dailyRenatal' + index" v-model="dailyRental[selectedVila][index]" :min="7000" :max="23000"
                :step="1000" />
              <label :class="season">{{ season }}</label>
              <!-- <input type="number" v-model="occupancies[index]">
              <input type="number" v-model="dailyRental[selectedVila][index]"> -->
            </div>

            <!-- <div class="chart-container">
                <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
    
            </div> -->

          </div>
          <div v-else class="">
            <h5 for="monthlyRental3Rooms">Monthly Rent</h5>
            <FpSlider :id="'monthlyRenatal'" v-model="monthlyRentals[selectedVila]" :min="100000" :max="200000"
              :step="1000" />
            <!-- <input id="monthlyRental3Rooms" type="number" v-model="monthlyRentals[selectedVila]"> -->

          </div>

        </div>
        <div class="yearly">
          <div>
            <div class="text-center ">Yearly income</div>
            <div class="ltr">{{ formatNum(yearlyIncome) }} &#3647;</div>
          </div>
          <div>
            <div class="text-center">Expenses</div>
            <div class="ltr">{{ formatNum(totalExpenses[selectedTerm]) }} &#3647;</div>
          </div>


        </div>
        <div class="profit">
          <div class="ltr">Annual Profit: {{ formatNum(annualProfit) }}  &#3647;</div>
          <!-- <div>Lease Profit: {{ formatNum(annualProfit * yearsOnLease) }}</div> -->
        </div>
        <div class="roi">R.O.I ({{ roi }}%) in x {{ roiInYears }} years</div>
      </div>

    </div>

    <!-- <div class="chartsWrapper"> -->
    <div class="expensis">
      <ExpensesChart 
        :selectedTerm="selectedTerm" 
        :selectedVila="selectedVila"
        @onExpensesChanged="handleExpensesChange"
        :yearlyIncome="yearlyIncome" />

    </div>
    <div class="seasons">
      <SeasonsChart :selectedTerm="selectedTerm" :seasons="seasons" :occupancies="occupancies" :days="days"
        :dailyRental="dailyRental" />

    </div>
    <div class="disclaimer">

    </div>


    <!-- </div> -->

  </div>
</template>

<script>

import SeasonsChart from "@/components/Calculator/Seasons.vue"
import ExpensesChart from "@/components/Calculator/Expenses.vue"
import FpSlider from "@/components/FpSlider";
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";
import { formatNumber } from "@/utils"
export default {
  name: 'FpCalculator',
  components: {
    SeasonsChart,
    ExpensesChart,
    FpSlider,
    FpCustomDropDown
  },
  props: {
    content: {
      type: Object,
      required: true
    },

  },
  data() {
    return {
      selectedTerm: 'shortTerm',
      selectedVila: 3,
      occupancies: [60, 75, 85],
      days: [54, 90, 127],
      seasons: ['Low', 'High', 'Peak'],
      yearsOnLease: 29,
      vilaTypes: [
        {
          label: '3-Rooms',
          value: 3
        },
        {
          label: '4-Rooms',
          value: 4  
        }
      ],
      termTypes:[
        {
          label: 'Short term',
          value: 'shortTerm'
        },
        {
          label: 'Long term',
          value: 'longTerm'
        }
      ],
      
      monthlyRentals: {
        3: 120000,
        4: 150000
      },
      dailyRental: {
        3: [12000, 14000, 16000],
        4: [14000, 16000, 18000]
      },
      initialInvestment: {
        3: 10900000,
        4: 12900000
      },
      // yearlyIncome: {
      //   shortTerm: 0,
      //   longTerm: 0
      // },
      leaseProfit: {
        shortTerm: 1000000,
        longTerm: 500000
      },
      totalExpenses: {
        shortTerm: 1000000,
        longTerm: 500000

      }
    }
  },
  computed: {
    annualProfit() {

      let profit = this.yearlyIncome - this.totalExpenses[this.selectedTerm]

      return profit
    },
    incomePerSeason() {
      return this.occupancies.map((occupancy, index) => {
        return occupancy * this.days[index] * this.dailyRental[this.selectedVila][index] / 100
      })
    },
    yearlyIncome() {
      if (this.selectedTerm === 'shortTerm') {
        return this.incomePerSeason[0] + this.incomePerSeason[1] + this.incomePerSeason[2]
      } else {
        return this.monthlyRentals[this.selectedVila] * 12
      }

    },
    roi() {
      return (this.annualProfit / this.initialInvestment[this.selectedVila] * 100).toFixed(0)
    },
    roiInYears() {
      return (this.initialInvestment[this.selectedVila] / this.annualProfit).toFixed(1)
    },
  },
  mounted() {
    console.log("mounted", this.content);
  },
  methods: {
    handleYealyIncomeChange(newValue) {
      // React to the change in the child component's property
      console.log('handleYealyIncomeChange:', newValue);
      if (this.selectedTerm === 'Short term') {

        this.yearlyIncome.shortTerm = newValue
      } else
        this.yearlyIncome.longTerm = newValue

    },
    handleExpensesChange(newValue) {
      // React to the change in the child component's property
      console.log('handleExpensesChange:', newValue);
      this.totalExpenses[this.selectedTerm] = newValue
      // Perform any action in response to the change
    },
    formatNum(num) {
      num = Math.round(num)
      return formatNumber(num)
    },
    setSelectedVila( option) {
      this.selectedVila = option.value
    },
    setSelectedTerm(option) {
      this.selectedTerm = option.value
    },
    setPotential(name){
      console.log(name)
      if (name === 'Good'){
        this.occupancies = [80, 90, 100]
        this.dailyRental = {
          3: [15000, 17000, 19000],
          4: [17000, 19000, 21000]
        }
      } else {
        this.occupancies = [60, 75, 85]
        this.dailyRental = {
          3: [12000, 14000, 16000],
          4: [14000, 16000, 18000]
        }
      }
    },
    getDropDownLabelByValue(value, options) {
      return options.find(option => option.value === value).label
    }
  }


}
</script>

<style lang="scss">
.wrapper {
  --blue: #5c9ce6;
  --peak: #00ACB3;
  --high: #47F8FF;
  --low: #5CE1E6;

  .Peak {
    color: var(--peak);
  }
  .High {
    color: var(--high);
  }
  .Low {
    color: var(--low);
  }

}

.chartsWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;



}

.selectVila {
  text-align: center;

  select {
    font-size: 2rem;
    background-color: black;
    color: white;
    border: none;
    margin: 1rem 3rem;
  }
}
.dropDowns {
  direction: ltr;
  width: 30rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin: 2rem auto;
  scale: 1.5;
}
h2 {
  text-align: center;
  border-bottom: 1px solid white;
  margin: 1rem;
  padding-bottom: .5rem;
}

.middleSection {
  display: flex;

  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;

  .inputRow {
    gap: 2rem;
    margin-top: 2rem;
  }

  input {
    margin: .5rem 1rem;
  }
}
.potentialButtons {
  display: flex;
  gap: 5rem;

  button {
    padding: 1rem 2rem;
    background-color: black;
    color: white;
    font-size: 1.5rem;
    border: 1px solid white;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

}
.inputs {
  height: 10rem;
  width: 30rem;
  margin-top: 1rem;

  h5{
    margin-bottom: 2rem;
  }
}

.headers {
  display: flex;
  justify-content: center;
  gap: 7rem;

  div {
    font-size: 1.2rem;
    border-bottom: 1px solid white;
    padding-bottom: .2rem;
  }
}

.yearly {
  display: flex;
  justify-content: space-between;
  font-size: 2rem !important;
  height: 6rem;
  margin-top: 2rem;
  gap: 5rem;
}

.profit {
  font-size: 3rem;
  height: 6rem;
}

.roi {
  font-size: 5rem;
}

.expensis {
  position: fixed;
  top: 10rem;
  right: 3rem;
}

.seasons {
  position: fixed;
  top: 12rem;
  left: 3rem;
  width: 25vw
}
.ltr {
  direction: ltr;

}
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.space-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}
</style>