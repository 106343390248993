<template>
	<transition appear>
		<div class="accessability-modal" v-if="show">
			<close-btn  @click="close"></close-btn>
			<Accessibility />

		</div>
	</transition>
</template>

<script>
import { mapGetters } from "vuex";
import CloseBtn from "@/components/buttons/CloseBtn";
import Accessibility from "@/components/Accessibility";
export default {
	name: "AccessibilityModal",
	components: {
		Accessibility,
		CloseBtn
	},

	computed: {
		show() {
			return this.$store.getters.accessibilityModal
		},
		...mapGetters(['accessibilityModal'])
	},
	methods: {
		close() {
			this.$store.commit('toggleAccessibilityModal', false)
		},
	},
}
</script>

<style scoped lang="scss">
.accessability-modal {
	padding: 1.534375rem;
	/*background-color: rgba(0, 0, 0, 0.8);*/
	background-color: rgba(31, 33, 35, 1);
	min-height: 5.1875rem;
	box-sizing: border-box;
	border-radius: 0.375rem;
	box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.69);
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	transform: translateX(0);
	z-index: 22;
	overflow: hidden;
	width: 100vw;
	padding-top: 5rem;
	:deep(.accessibility-wrapper){
		font-size: 1.3rem;
		width: 100%;

	}
	&.v-enter-active,
	&.v-leave-active {
		transition: transform .3s ease;
	}

	&.v-enter-from,
	&.v-leave-to {
		transform: translateX(200%);
	}
}
</style>

<style>
.am-blanket {
	position: fixed;
	z-index: 190;
	background-color: rgba(0, 0, 0, 0.40) !important;
	width: 100%;
	height: 100svh;

	top: 0;
	left: 0;
}
</style>