<template>
		<div v-if="globalLoading" class="loader">
				<lottie-player
						:src="loadingAnimationPath"
						:background="background"
						speed="1"
						style="width: 300px; height: 300px;"
						loop autoplay
				></lottie-player>
		</div>
</template>

<script>
import {appUrl} from "@/http"
import {mapGetters} from "vuex";
export default {
		name: "LottieLoader",
		computed: {
				background(){
						return 'transparent'
				},
				loadingAnimationPath(){
						if(window.loadingAnimationLottieFile){
								return window.loadingAnimationLottieFile
						}
						return `${this.appUrl}lottie-animations/43472-self-building-house.json`
				},
				appUrl(){
						return appUrl;
				},
				...mapGetters(['globalLoading'])
		}
}
</script>

<style scoped>
lottie-player {
		border-radius: 6px;
		overflow: hidden;
}
</style>
