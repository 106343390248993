<template>
    <div class="">
        <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
// import { formatNumber } from "@/utils"

export default {
    name: 'SeasonsChart',
    components: {
        //   apexchart: VueApexCharts,
    },
    props: {
        selectedTerm: {
            type: String,
            default: 'shortTerm'
        },
        seasons: {
            type: Array,
            default: () => []
        },
        occupancies: {
            type: Array,
            default: () => []
        },
        days: {
            type: Array,
            default: () => []
        },
        dailyRental: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {

            chartOptions: {
                chart: {
                    type: 'bar',
                    width: 400,
                    height: 550,
                    toolbar: {
                    show: false // This line disables the toolbar
                }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Low', 'High', 'Peak'],
                    labels: {
                        style: {
                            colors: ['#FFFFFF', '#FFFFFF', '#FFFFFF'], // Make x-axis labels white
                        }
                    }
                },
                yaxis: {
                    // title: {
                    //     text: '$ (thousands)'
                    // },
                    labels: {
                        style: {
                            colors: ['#FFFFFF'], // Make x-axis labels white
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands"
                        }
                    }
                }
            },


        }
    },
    computed: {
        series() {
            return [{
                name: '3-Rooms',
                data: this.seasons.map((season, index) => {
                    return this.occupancies[index] * this.days[index] * this.dailyRental[3][index] / 100
                })
            },
            {
                name: '4-rooms',
                data: this.seasons.map((season, index) => {
                    return this.occupancies[index] * this.days[index] * this.dailyRental[4][index]  / 100
                })
            },
            ]
        },

    },


}
</script>

<style>
.chart-container {
    width: 50rem;
}

</style>