<template>
    <div ref="map" class="google-map"></div>
</template>

<script>
export default {
    name: "GoogleMap",
    props: {
        mapId: null,
        center: null,
        zoom: null,
        landmarkGroups: null,
        options: null
    },
    // computed:{
    //     mapCenter(){
    //         console.log("mapCenter", this.center)
    //         if(this.center){
    //             return this.center
    //         }
    //         return null
    //     }
    // },
    data(){
        return {
            isInit: false,
            map: null,
            markers: []
        }
    },
    watch:{
        // center(){
        //     this.setCenterAndLandmarks()
        // },
        zoom(){
            this.setZoom()
        }
    },
    methods: {
        init(){
            const google = window.google
            if(google){
                this.map = new google.maps.Map(this.$refs.map, {
                    zoom: 9,
                    center: new google.maps.LatLng(this.center.lat, this.center.lng),
                    mapId: this.mapId,
                    disableDefaultUI: true,
                    mapTypeControl: true
                });

                if(this.center){
                    this.setCenterAndLandmarks()
                }

                if(this.options){
                    this.map.setOptions(this.options)
                }

                this.setZoom()
            }
        },
        // setMapCenter(position, zoom = "16"){
        //     if(typeof position === 'string'){
        //         this.map.setCenter(this.stringToCoords(position))
        //     }else{
        //         this.map.setCenter(position)
        //     }
        //     if(typeof zoom === "string"){
        //         this.map.setZoom(parseFloat(zoom))
        //     }else{
        //         this.map.setZoom(zoom)
        //     }
        // },
        setZoom(){
            if(typeof this.zoom === "string"){
                this.map.setZoom(parseFloat(this.zoom))
            }else{
                this.map.setZoom(this.zoom)
            }
        },
        setCenterAndLandmarks(){
            console.log("setCenterAndLandmarks", this.mapCenter)
            this.map.setCenter(this.center)
            this.map.setZoom(16)
            if(this.landmarkGroups && Array.isArray(this.landmarkGroups) && this.landmarkGroups.length > 0){
                // console.log("this.landmarkGroups", this.landmarkGroups)
                this.landmarkGroups.forEach((landmarkGroup) => {
                    if(landmarkGroup.landmarks){
                        landmarkGroup.landmarks.forEach((landmark) => {
                            if(landmark.coordinates){
                                const position = this.stringToCoords(landmark.coordinates)
                                if(position){
                                    const markerOption = {position}
                                    if(landmarkGroup.icon){
                                        markerOption.icon = landmarkGroup.icon
                                    }
                                    if(landmark.address){
                                        markerOption.title = landmark.address
                                    }
                                    this.addMarker(markerOption)
                                }
                            }
                        })
                    }
                })
                this.$emit('markers', this.markers)
            }
        },
        addMarker({position, title, icon}){
            const google = window.google
            const marker = new google.maps.Marker({
                position,
                map: this.map,
                title,
                icon,
                animation: google.maps.Animation.DROP,
                draggable: false
            });

            marker.addListener('click', this.markerClicked)
            if(!this.markers.find(m => m.position.lat() === position.lat && m.position.lng() === position.lng)){
                this.markers.push(marker)
            }
        },
        toggleLandmarkGroup(lmg){
            if(typeof lmg.visible === 'undefined'){
                lmg.visible = false
            }
            if(lmg && lmg.landmarks){
                lmg.landmarks.forEach(lm => {
                    this.markers.forEach(marker => {
                        const latlng = lm.coordinates.split(",")
                        const lat = parseFloat(latlng[0])
                        const lng = parseFloat(latlng[1])
                        if(lat === marker.position.lat() && lng === marker.position.lng()){
                            marker.setVisible(lmg.visible)
                        }
                    })
                })
            }
        },
        stringToCoords(coordsStr){
            if(coordsStr){
                const cords = coordsStr.split(",")
                if(cords[0] && cords[1]){
                    return {lat: parseFloat(cords[0]), lng: parseFloat(cords[1])}
                }
            }
            return null
        },
        markerClicked(e){
            this.$emit('select', `${e.latLng.lat()},${e.latLng.lng()}`)
        }
    },
    mounted() {
        if(!this.isInit){
            let checkCount = 0
            const inter = setInterval(() => {
                checkCount++
                if(window.google){
                    this.init()
                    this.isInit = true
                    clearInterval(inter)
                } else if(checkCount > 50){
                    this.isInit = true
                    clearInterval(inter)
                    console.warn(`google maps script did not loaded after ${(checkCount * 200)} milliseconds`)
                }
            }, 200)
        }
    }
}
</script>

<style scoped lang="scss">
    .google-map {
        height: 100%;
    }

    .is-mobile {
        .google-map {
            min-height: 60vh;
        }
    }
</style>
