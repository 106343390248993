<template>
  <div v-html="svgContentSized" />
</template>

<script>
export default {
  props: ['iconName', 'size'],
  data() {
    return {
      svgContent: '',
    };
  },
  computed: {
    svgContentSized() {
      return this.svgContent.replace('<svg ', `<svg width="${this.size}" height="${this.size}" `);
    }
  },
  async mounted() {
    try {
      this.svgContent = await this.loadIcon(this.iconName);
    } catch (error) {
      console.error('Error loading icon:', error);
    }
  },
  methods: {
    async loadIcon(iconName) {
      const iconPath = require(`@/assets/icons/${iconName}.svg`);
      const response = await fetch(iconPath);

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      return await response.text();
    },
  },
};
</script>
