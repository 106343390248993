<template>
		<div class="weather-widget-wrapper" v-if="enabled">
				<div class="weather-widget" v-if="weatherCity">
						<div class="city-time flex align-center">
							<span class="ml-1">{{weatherCity?.time}}</span>
							<img src="../assets/weather/svg/clock.svg" alt="" width="20px" height="20px">
						</div>
						<div class="city-temp flexalign-center  mr-3">
							<div class="city-name">{{locale == 'he' ? weatherCity?.name_he : weatherCity?.name_en}}</div>
							<span>{{weatherCity?.weather?.Temperature}}&deg;</span>
							<img src="../assets/weather/svg/sun.svg" alt="" width="20px" height="20px">
						</div>
				</div>
		</div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
		name: "WeatherWidget",
		computed: {
				enabled(){
						return this.weatherWidget && this.weatherWidget.show
				},
				...mapGetters(['settings','weatherWidget', 'weatherCity', 'locale'])
		},
		data(){
				return {
						loading: false,
						interval: null
				}
		},
		async mounted() {
				this.loading = true
				await this.$store.dispatch('loadWeatherCity')
				this.loading = false
				this.interval = setInterval(() => {
						this.$store.dispatch('loadWeatherCity')
				}, 30000)
		},
		beforeUnmount() {
				if(this.interval){
						clearInterval(this.interval)
				}
		}
}
</script>

<style scoped lang="scss">
.weather-widget-wrapper {
	position: relative;
	display: flex;
	justify-content: flex-end;
	z-index: 6;
	.weather-widget {
		

			backdrop-filter: blur(5px);
			border-radius: 4px;
			margin: 10px;
			padding: 8px 10px;
			display: flex;
			font-size: 1.1rem;
			gap: 10px;
			align-items: center;

			.city-name {
					flex-grow: 1;
					text-align: center;
					margin: 0 1rem;
			}

			.city-temp {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
	
			}
		img {
			width: 25px;
			margin: 0 5px;
		}
	}
}
</style>
