<template>
    <transition appear>
        <div class="apartments-filter" v-if="filterEnabled">
            <Accordion ref="accordionRef" :headerSyle="{background: 'rgba(53, 53, 53, 0.90)'}" :initialOpen="!collapsed">
                <template v-slot:header>
                    <div class="text-right flex between w-100 align-center header">
                        <button class="btn btn-secondary-outline clearButton" @click="clearResults">
                            {{ $t('איפוס') }}
                        </button>
                        <span class="headerText">{{ apartmentsFilterTitle }}</span>
                    </div>
                </template>
                <template v-slot:content>
                    <div class="tabs-contents">
                        <div class="tab" v-if="currentTab === 'residence'">
                            <div v-if="settings.apartment_types.show" class="row">
                                <div
                                    class="flex between align-center fullWidth mb-2"
                                >
                                    <fp-custom-drop-down
																				:value="filters.residence.type"
																				:name="$t('טיפוס')"
                                        :options="options.type"
																				@change="residenceFilter($event, 'type')"
                                        :styleObject="{maxHeight: '150px'}"
																		></fp-custom-drop-down>
                                </div>
                            </div>
                            <div v-if="settings.buildings.show" class="row">
                                <div class="flex between align-center fullWidth mb-2">
                                    <fp-custom-drop-down
                                        :value="filters.residence.building"
                                        :name="$t('בניין')"
                                        :options="options.building"
                                        :empty-option="true"
                                        @change="residenceFilter($event, 'building')"
                                    ></fp-custom-drop-down>
                                </div>
                            </div>
                            <div class="row">
                                <div
                                    v-if="settings.floors.show && options.floor.length"
                                    class="flex between align-center fullWidth mb-2"
                                >
                                    <span>{{ $t('קומות') }}</span>
                                    <div class="w-75">
                                        <FpSlider
                                            v-if="options.floor.length > 1"
                                            :value="filters.residence.floor ? filters.residence.floor : [+options.floor[0], +options.floor[options.floor.length - 1]]"
                                            :min="+options.floor[0]" :max="+options.floor[options.floor.length - 1]"
                                            @update="value => residenceFilter(value, 'floor')"
                                        />
                                    </div>
                                </div>
                                <div class="flex between align-center fullWidth"
                                    v-if="settings.rooms.show && options.floor.length">
                                    <span>{{ $t('חדרים') }}</span>
                                    <div class="w-75">
                                        <FpSlider
                                            v-if="options.rooms.length > 1"
                                            :value="filters.residence.rooms ? filters.residence.rooms : [+options.rooms[0], +options.rooms[options.rooms.length - 1]]"
                                            :min="+settings.rooms.options[0]"
                                            :max="+settings.rooms.options[settings.rooms.options.length - 1]"
                                            @update="value => residenceFilter(value, 'rooms')"
                                        />
                                    </div>
                                </div>
                            </div>
                          <template v-if="dynamicFieldsFilters && dynamicFieldsFilters.length">
                            <div v-for="(dynamicFilter, dfi) in dynamicFieldsFilters" :key="dfi" class="row">
                              <div class="flex between align-center fullWidth mb-2">
                                <template v-if="dynamicFilter.type === 'select'">
                                  <fp-custom-drop-down
                                    :value="filters.residence[dynamicFilter.filter_by_product_dynamic_field]"
                                    :name="dynamicFilter.label"
                                    :options="[...dynamicFilter.options]"
                                    :empty-option="true"
                                    @change="residenceFilter($event, dynamicFilter.filter_by_product_dynamic_field)"
                                  ></fp-custom-drop-down>
                                </template>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div class="tab" v-else-if="currentTab === 'offices'">O</div>
                        <div class="tab" v-else-if="currentTab === 'hotels'">H</div>
                    </div>
                    <slot />
                </template>
            </Accordion>
        </div>
    </transition>
</template>

<script>
// import FpRadioGroup from "@/components/buttons/FpRadioGroup";
// import FpSelect from "@/components/FpSelect";
import FpSlider from "@/components/FpSlider";
import FpCustomDropDown from "@/components/buttons/FpCustomDropDown";
import {mapGetters} from "vuex";
// import FpButton from "@/components/buttons/FpButton";
// import FpIconButton from "@/components/buttons/FpIconButton";
import Accordion from "@/components/FpAccordion.vue"

const defaultSettings = {
    quarters: {
        label: "שכונה",
        show: true,
        options: []
    },
    plots: {
        label: "מגרש",
        show: true,
        options: []
    },
    buildings: {
        label: "בניין",
        show: true,
        options: []
    },
    rooms: {
        label: "חדרים",
        show: true,
        options: [
            "2",
            "3",
            "4",
            "5",
            "6"
        ]
    },
    apartment_types: {
        label: "טיפוס",
        show: true,
        options: []
    },
    floors: {
        label: "קומה",
        show: true,
        options: []
    }
}

export default {
    name: "ApartmentsFilter",
    components: { FpSlider, FpCustomDropDown, Accordion },
    data() {
        return {
            currentTab: 'residence',
            tabs: [
                {
                    id: 'residence',
                    label: 'מגורים'
                },
                {
                    id: 'offices',
                    label: 'משרדים'
                },
                {
                    id: 'hotels',
                    label: 'מלונאות'
                }
            ],
            filters: {
                residence: {
                    building: null,
                    quarter: null,
                    plot: null,
                    rooms: null,
                    type: null,
                    floor: null,
                }
            },
            options: {
                building: ['1', '2', '3', '4'],
                rooms: ['2', '3', '4', '5', '6'],
                type: ['A', 'B', 'C', 'D'],
                quarters: ['A', 'B', 'C', 'D'],
                plots: ['A', 'B', 'C', 'D'],
                floor: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            },
            collapsed: false,
        }
    },
    computed: {
        filterEnabled() {
            return !(this.settings.floors.show === false &&
                this.settings.apartment_types.show === false &&
                this.settings.rooms.show === false &&
                this.settings.quarters.show === false &&
                this.settings.plots.show === false &&
                this.settings.buildings.show === false)
        },
        storeFilter() {
            return this.settings && this.settings.options && this.settings.options.store_filter
        },
        settings() {
            if (this.$store.getters.settings && this.$store.getters.settings.apartments_filter) {
                return this.$store.getters.settings.apartments_filter
            }
            return defaultSettings
        },
        dynamicFieldsFilters(){
          if(this.settings && this.settings.dynamic_fields){
              return this.settings.dynamic_fields
          }
          return null
        },
        buildingMandatory() {
            if (this.settings && this.settings.buildings && typeof (this.settings.buildings.mandatory) !== 'undefined') {
                return this.settings.buildings.mandatory
            }
            return false
        },
        defaultBuildingOption() {
            if (this.settings && this.settings.buildings && this.settings.buildings.default_building_option) {
                return this.settings.buildings.default_building_option
            }
            return null
        },

        ...mapGetters(['isMobile', 'lowResLaptop', 'isSideMenu', 'filter', 'isModelApp', 'apartmentsFilterTitle', 'apartmentsFilter'])
    },
    watch: {
        filters: {
            handler(newVal) {
                if (!this.isMobile) {
                    this.$emit('filter', newVal[this.currentTab])

                }
                if (this.storeFilter) {
                    localStorage.setItem('apartments_filter', JSON.stringify(newVal))
                }
            },
            deep: true
        },
        settings(newVal) {
            if (newVal) {
                this.setOptions()
            }
        },
        filter(newVal) {
            if (newVal) {
                this.setOptions()
            }
        }
    },
    methods: {
        selectTab(tab) {
            this.currentTab = tab.id
        },
        residenceFilter(option, filterName) {
            if(option && option.value === null){
              this.filters[this.currentTab][filterName] = null;
            }else{
              this.filters[this.currentTab][filterName] = option.label || option
            }
            this.$emit('filter', this.filters[this.currentTab])
        },
        setOptions() {
            if (this.settings.buildings.options) {
                this.options.building = this.settings.buildings.options
            }

            if (this.settings.plots.options) {
                this.options.plots = this.settings.plots.options
            }

            if (this.settings.rooms.options) {
                this.options.rooms = this.settings.rooms.options
            }

            if (this.settings.quarters.options) {
                this.options.quarters = this.settings.quarters.options
            }

            if (this.settings.apartment_types.options) {
                this.options.type = this.settings.apartment_types.options
            }

            if (this.settings.floors.options) {
                this.options.floor = this.settings.floors.options
            }
            if (this.settings.buildings.options) {
                this.options.building = this.settings.buildings.options
            }

            // if(this.settings.buildings && this.settings.buildings.data_source === 'dynamic' && this.filter?.buildings){
            //     this.options.building = this.filter?.buildings
            //     if(this.defaultBuildingOption && !this.filters.residence.building){
            //             this.filters.residence.building = this.defaultBuildingOption
            //     }
            // }

            if (this.settings.buildings && this.settings.buildings.data_source === 'dynamic' && this.filter?.buildings) {
                this.options.building = this.filter?.buildings
            }

            if (this.settings.plots && this.settings.plots.data_source === 'dynamic' && this.filter?.plots) {
                this.options.plots = this.filter?.plots
            }

            if (this.settings.quarters && this.settings.quarters.data_source === 'dynamic' && this.filter?.quarters) {
                this.options.quarters = this.filter?.quarters
            }

            if (this.settings.apartment_types && this.settings.apartment_types.data_source === 'dynamic' && this.filter?.types) {
                this.options.type = this.filter?.types
            }

            if (this.settings.floors && this.settings.floors.data_source === 'dynamic' && this.filter?.floors) {
                this.options.floor = this.filter?.floors
            }
        },
        clearResults(e) {
            e.stopPropagation()
            Object.keys(this.filters[this.currentTab]).forEach(key => {
                this.filters[this.currentTab][key] = null
            })
            this.$emit('filter', this.filters[this.currentTab])
        },
        closeCollapse() {
            this.collapsed = true
            this.$refs.accordionRef.toggle(false)
        }
    },
    mounted() {

        if (this.storeFilter) {
            const apartmentsFilterStr = localStorage.getItem('apartments_filter')
            if (apartmentsFilterStr) {
                this.filters = JSON.parse(apartmentsFilterStr)
            }
        }
        this.setOptions()
        if (this.isModelApp) {
            if (this.isMobile) this.collapsed = true
        } else {
            if (this.isSideMenu) {
                this.collapsed = false
            } else {
                this.collapsed = !!this.lowResLaptop;
            }
        }

        if(this.dynamicFieldsFilters){
          this.dynamicFieldsFilters.forEach((dynamicField) => {
            this.filters.residence[dynamicField.filter_by_product_dynamic_field] = null;
          })
        }
    }
}
</script>

<style scoped lang="scss">
span {
    color: #FFF;
    text-align: right;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.apartments-filter {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);
    background: rgba(36, 36, 36, 1) !important;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 5px;
    width: 31.18675rem;
    max-width: calc(100vw - 2rem);
    flex-grow: 0;
    margin-bottom: auto;
    z-index: 1;

    .clearButton {
        height: 30px !important;
        margin-left: 1rem;
    }

    .filter-header {
        display: flex;
        flex-flow: row nowrap;
        border-bottom: solid 1px #fff;
    }

    .tabs-contents {
      position: relative;
      z-index: 15;
    }

    .tabs {
        flex-grow: 1;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        li {
            padding: 15px;
            flex-grow: 1;
            text-align: center;
            cursor: pointer;

            &::after {
                display: none;
                content: "";
                width: 100%;
                height: 3px;
                background-color: var(--primary);
                position: absolute;
                top: 100%;
                left: 0;
                margin-top: -1px;
            }

            &.active {
                position: relative;
                font-weight: 700;
                color: var(--primary);

                &::after {
                    display: block;
                }
            }

        }
    }

    .tab {
        padding: 0.9375rem 1.875rem 1.875rem;

        .fp-radio-group {
            margin-bottom: 1rem;
        }
    }
}

.headerText {
    margin: 0 .3rem;
}

.apartments-filter-header {
    background: rgba(53, 53, 53, 0.90);

}

.is-mobile {
    .apartments-filter {
        width: 100%;
        max-width: none;
        border: none;
        border-radius: 0;
        background-image: url("../assets/filter-bg.png");
        background: rgba(36, 36, 36, 1);
        background-position: left bottom;
        background-size: contain;
        background-repeat: no-repeat;

        :deep(.row) {
            display: flex;
            flex-flow: row wrap;
            gap: 1rem;
            justify-content: space-between;
            width: calc(100vw - 1.875rem * 2);
        }
    }

    .form-buttons {
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-top: 1rem;
    }
}

.fp-side-menu-wrapper+.fp-app-wrapper {
    .apartments-filter {
        flex-grow: 0;
        margin-bottom: 0;
        border-radius: 0;
        border: none;
        width: auto;

        backdrop-filter: blur(5px);
        background: rgba(36, 36, 36, 1);
        background-image: none;

        .fp-radio-group {
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: space-between;

            :deep(.fp-radio-group-label) {
                margin-bottom: 0;
            }
        }

        .fp-select-wrapper {
            display: flex;
            align-items: center;
            gap: 10px;
            justify-content: space-between;
        }
    }
}
</style>
