<template>
    <div class="page floors fade-in">
				<div class="apt-fm">
						<div class="filter-list flex flex-column">
								<floors-filter @filter="filterChanged"></floors-filter>
								<template v-if="isSideMenu">
										<!--<apartments-table
												v-if="floorFilterOptions.floors_list_layout === 'table'"
												:apartments="filteredFloors"
												:current-apt="floorsPageCurrent"
												:loading="loading"
												:show-compare="false"
												@apartment-clicked="openFloor"
										></apartments-table>-->
										<floors-list
												:floors="filteredFloors"
												:current-apt="floorsPageCurrent"
												:loading="loading"
												scroll="vertical"
												@floor-clicked="openFloor"
										></floors-list>
								</template>
						</div>
						<transition>
								<div v-if="floorsPageCurrent" class="floor-details-pane">
										<floor-modal
												:floor="floorsPageCurrent"
												:show-buttons="true"
												:show-link="false"
												:navigate-on-button-click="true"
												:show-thumbnail="true"
												:next-floor="nextFloorPage"
												:previous-floor="previousFloorPage"
												@close="floorsPageCurrent = null"
												@floor-clicked="navigateToFloor"
												@previous-floor-clicked="openFloor"
												@next-floor-clicked="openFloor"
										></floor-modal>
								</div>
						</transition>
				</div>
        <floors-list
						v-if="!isSideMenu"
						:floors="filteredFloors"
						:loading="loading"
						@floor-clicked="openFloor"
				></floors-list>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import FloorModal from "@/components/FloorModal.vue";
import FloorsList from "@/components/FloorsList.vue";
import FloorsFilter from "@/components/FloorsFilter.vue";

export default {
    name: "FloorsPage",
    components: {FloorsFilter, FloorsList, FloorModal},
    computed: {
				floorsPageCurrent: {
						get(){
								return this.$store.getters.floorsPageCurrent
						},
						set(floorsPageCurrent){
								this.$store.commit('floorsPageCurrent', floorsPageCurrent)
						}
				},
				...mapGetters(['isSideMenu', 'currentProjectId', 'floorFilterOptions', 'floors'])
    },
    data(){
        return {
            filter: null,
            filteredFloors: {},
            loading: false,
						nextFloorPage: null,
						previousFloorPage: null
        }
    },
    methods: {
        // loadProject(to){
        //     const id = to.params.id ? parseInt(`${to.params.id}`, 10) : null
        //     //this.$store.commit('selectProject', id)
				// 		this.$store.dispatch('changeProject', {id})
        // },
        async searchFloors(){
            if(this.filter || Object.keys(this.floors).length <= 1){
                this.loading = true
                this.filteredFloors = await this.$store.dispatch('searchFloors', {filter: this.filter})
                this.loading = false
            }else{
								await this.$store.dispatch('searchFloors')
                this.filteredFloors = this.floors
            }
        },
        async filterChanged(filter){
            this.filter = filter
            await this.searchFloors()
        },
				openFloor(floor){
						if(this.isSideMenu){
								if(this.floorsPageCurrent){
										if(floor.id === this.floorsPageCurrent.id){
												this.floorsPageCurrent = null
												this.nextFloorPage = null
												this.previousFloorPage = null
										}else{
												this.floorsPageCurrent = null
												setTimeout(() => {
														this.floorsPageCurrent = floor
												}, 300)
										}
								}else{
										this.floorsPageCurrent = floor
								}

								const keys = Object.keys(this.filteredFloors)
								const index = keys.indexOf(`${floor.id}`)
								if(index > -1){
										const nextIndex = index + 1
										const previousIndex = index - 1
										if(keys[nextIndex]){
												this.nextFloorPage = this.filteredFloors[keys[nextIndex]]
										}else{
												this.nextFloorPage = null
										}
										if(keys[previousIndex]){
												this.previousFloorPage = this.filteredFloors[keys[previousIndex]]
										}else{
												this.previousFloorPage = null
										}
								}
						}else{
							this.navigateToFloor(floor)
						}
				},
				navigateToFloor(floor){
						this.$router.push({name: 'floor', params: {projectId: this.currentProjectId, floorId: floor.id}})
				}
    },
		beforeRouteEnter(to, from, next){
        next(vm => {
            //vm.loadProject(to)
            vm.searchFloors()
        })
    },
    beforeRouteUpdate(){
        this.searchFloors()
    }
}
</script>

<style lang="scss">
    .page.floors {
        display: flex;
        flex-flow: column;
        justify-content: flex-end;

				.floors-list {
						margin-top: 1rem;
				}

				.floor-details-pane {
						&.v-enter-active,
						&.v-leave-active {
								transition: transform .3s ease;
						}

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(-500px);
						}
				}

				.floors-table {
						border-top: solid 1px #fff;
						padding: 5px;
						width: 100%;
				}
    }

		.fp-side-menu-wrapper + .fp-app-wrapper .page.floors {
				.apt-fm {
						display: flex;
						flex-flow: row nowrap;
						justify-content: space-between;
						flex-grow: 1;

						.filter-list {
								height: 100svh;

								.floors-table {
										flex-grow: 1;
								}
						}

						.floors-nav-arrows {
								margin-left: 10px;
								margin-right: 10px;
						}

						.floor-modal {
								border: none;
								border-radius: 0;
								backdrop-filter: blur(5px);
								background-color: rgba(0, 0, 0, 0.8);
								background-image: none;
								margin: 10px 10px 50px;

								.modal-content {
										flex-grow: 0;
								}
						}
				}
		}

		.ltr .page.floors {

				.floor-modal {
						margin-right: 10px;
						margin-left: 0;
				}

				.floor-details-pane {

						&.v-enter-from,
						&.v-leave-to {
								transform: translateX(500px);
						}
				}
		}
</style>
