<template>
    <div class="page floor fade-in">
        <div class="centered-content">
						<transition appear>
								<div v-if="floor" class="floor-modal-wrapper">
										<floor-modal
												:floor="floor"
												:show-thumbnail="true"
												:class="{'scrollable-y': isSideMenu}"
										></floor-modal>
								</div>
						</transition>
            <transition appear>
                <content-modal
										v-if="floorContent" @close="closeContent"
                    :show-back-button="false"
										:class="{
												'full': floorContentType === 'gallery',
												'tabs': hasTabs,
												'inner-tabs': hasInnerTabs
										}"
								>
                    <template #header v-if="floorContent.tabs_layout === 'top_tabs'">
                        <span v-if="floorContent.label && floorContentType !== 'gallery'">{{floorContent.label}}</span>
                        <nav v-else-if="floorContent.tabs && floorContent.tabs.length > 1" class="header-tabs">
                          <ul>
                            <li v-for="(tab, tabIndex) in floorContent.tabs" :key="tabIndex">
                              <button type="button" :class="{active: currentContentTab === tabIndex}" @click="changeContentTab(tabIndex)">{{tab.label}}</button>
                            </li>
                          </ul>
                        </nav>
                    </template>
                    <building-specs v-if="floorContentType === 'specifications'" :content="floorContent"></building-specs>
                    <div class="gallery-slides" v-else-if="floorContentType === 'gallery'">
                        <div v-if="floorContentCurrentTab">
														<masonry-gallery
																v-if="floorContentCurrentTab.acf_fc_layout === 'gallery' && floorContentCurrentTab.type === 'masonry'"
																:images="floorContentCurrentTab.gallery"
																:items-per-row="isMobile ? floorContentCurrentTab.mobile_items_per_row : floorContentCurrentTab.items_per_row"
														></masonry-gallery>
                            <slider-gallery v-else-if="floorContentCurrentTab.acf_fc_layout === 'gallery'" :slides="floorContentCurrentTab.gallery">
                                <template v-slot="{slide}">
                                    <div
																				v-if="currentContentTabClickAction === 'edit' && !isMobile"
																				:class="`img-${slide.image_layout} image-edit`"
																				@click="$store.dispatch('openImageEditor', slide.url)"
																				tabindex="1"
																		>
                                        <img :src="slide.url" :alt="slide.alt">
																				<click-here></click-here>
                                    </div>
																		<div v-else-if="slide.type === 'image'" class="ps-gal" :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																				<a
																						:href="slide.url"
																						:data-pswp-width="slide.width"
																						:data-pswp-height="slide.height"
																						target="_blank"
																						rel="noreferrer"
																				>
																						<img :src="slide.thumbnail" :alt="slide.alt">
																				</a>
																		</div>
																		<div v-else :class="[`type-${slide.type}`, `img-${slide.image_layout}`]">
																				<video :src="slide.url" controls></video>
																		</div>
                                </template>
                            </slider-gallery>
                            <div class="iframe" v-else-if="floorContentCurrentTab.acf_fc_layout === 'iframe'">
                                <iframe v-if="floorContentCurrentTab.src" :src="floorContentCurrentTab.src"></iframe>
																<illustration-only-disclaimer></illustration-only-disclaimer>
                            </div>
														<masonry-gallery
																v-else-if="floorContentCurrentTab.acf_fc_layout === 'masonry'"
																:images="floorContentCurrentTab.gallery"
																:items-per-row="isMobile ? floorContentCurrentTab.mobile_items_per_row : floorContentCurrentTab.items_per_row"
														></masonry-gallery>
                        </div>
                    </div>
                    <div class="flipbook-wrapper" v-else-if="floorContentType === 'flipbook'">
                      <fp-flip-book :pages="floorContent.images"></fp-flip-book>
                    </div>
										<template #footer>
												<nav
														v-if="floorContent.tabs_layout === 'bottom_buttons' && floorContent.tabs && floorContent.tabs.length > 1"
														class="footer-tabs"
												>
														<fp-button
																v-for="(tab, tabIndex) in floorContent.tabs"
																:key="tabIndex"
																:class="{active: currentContentTab === tabIndex}"
																@click="changeContentTab(tabIndex)"
														>{{tab.label}}</fp-button>
												</nav>
										</template>
                </content-modal>
            </transition>
        </div>
    </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import ContentModal from "@/components/ContentModal"
import BuildingSpecs from "@/components/BuildingSpecs"
import SliderGallery from "@/components/SliderGallery"
import FpFlipBook from "@/components/FpFlipBook"
import IllustrationOnlyDisclaimer from "@/components/IllustrationOnlyDisclaimer"
import MasonryGallery from "@/components/MasonryGallery"
import FpButton from "@/components/buttons/FpButton"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import ClickHere from "@/components/ClickHere.vue";
import FloorModal from "@/components/FloorModal.vue";

export default {
    name: "FloorPage",
    components: {
				ClickHere,
				FpButton,
				MasonryGallery,
				IllustrationOnlyDisclaimer,
				FpFlipBook,
				SliderGallery,
				BuildingSpecs,
				ContentModal,
				FloorModal
		},
    computed: {
				hasTabs(){
						return this.floorContent.tabs && this.floorContent.tabs.length > 1
				},
				hasInnerTabs(){
						return this.hasTabs && this.floorContentType === 'specifications'
				},
        floor(){
            if(this.currentFloorId && this.$store.state.floors && this.$store.state.floors[this.currentFloorId]){
                return this.$store.state.floors[this.currentFloorId]
            }
            return null
        },
        floorContentType(){
            return this.$store.getters.floorContentType
        },
        floorContentCurrentTab(){
						if(this.floorContent && this.floorContent.tabs && this.floorContent.tabs[this.currentContentTab] && this.floorContent.tabs[this.currentContentTab].content){
								if(this.floorContent.tabs[this.currentContentTab].content[0]){
									return this.floorContent.tabs[this.currentContentTab].content[0]
								}
								return this.floorContent.tabs[0].content[0]
						}
						return null
        },
				currentContentTabClickAction(){
						if(this.floorContentCurrentTab?.click_action){
								return this.floorContentCurrentTab.click_action
						}
						return null
				},
        ...mapState(['currentFloorId', 'currentProjectId']),
        ...mapGetters([
						'isMobile',
						'floorContent',
						'isSideMenu',
						'floors'
				])
    },
		watch:{
				floorContent(){
						this.changeContentTab(0)
				},
				floors(){
						this.loadFloor(this.$route)
				}
		},
    data(){
      return {
        currentContentTab: 0,
				lightbox: null
      }
    },
    methods: {
        changeContentTab(tabIndex){
						this.currentContentTab = tabIndex

						this.destroyPs()
						this.$nextTick().then(() => {
								this.initPs()
						})
        },
        async loadFloor(to) {
            const id = to.params.floorId ? parseInt(`${to.params.floorId}`, 10) : null
            await this.$store.dispatch('loadFloor', id)
            this.openFirstContent()
        },
        closeContent(){
            this.$store.commit('floorContent', null)
        },
        openFirstContent(){
						if(!this.isMobile){
								if(!this.floorContent){
										if(this.floor && this.floor.buttons[0]){
												const firstContent = this.floor.buttons[0]
												this.$store.commit('floorContent', firstContent)
										}else if(this.floorContent){
												this.$store.commit('floorContent', null)
										}
								}
						}
        },
        goToApartments(){
            this.$router.push({name: 'apartments', params: {id: this.currentProjectId}})
        },
				initPs(){
						if(this.currentContentTabClickAction !== 'edit' || this.isMobile){
								if (!this.lightbox) {
										this.lightbox = new PhotoSwipeLightbox({
												gallery: '.gallery-slides',
												children: '.ps-gal a',
												pswpModule: () => import('photoswipe'),
										});
										this.lightbox.init();
								}
						}
				},
				destroyPs(){
						if (this.lightbox) {
								this.lightbox.destroy();
								this.lightbox = null;
						}
				}
    },
		mounted() {
				this.destroyPs()
				this.$nextTick().then(() => {
						this.initPs()
				})
		},
		beforeRouteEnter(to, from, next) {
        next(async vm => {
            await vm.loadFloor(to)
        })
    },
		beforeRouteLeave(){
				this.closeContent()
		},
    async beforeRouteUpdate(to) {
        await this.loadFloor(to)
    },
}
</script>

<style scoped lang="scss">
.page.floor {
    display: flex;
    align-items: flex-start;
    padding: 0 2.6875rem;

    .centered-content {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        flex-grow: 1;
        gap: 15px;
        max-width: calc(100vw - 2.6875rem * 2);
    }
}

.floor-modal-wrapper {
		&.v-enter-active,
		&.v-leave-active {
				transition: transform .5s ease;
		}

		&.v-enter-from,
		&.v-leave-to {
				transform: translateX(500px);
		}
}

.floor-modal {
    width: 311px;
    max-width: calc(100vw - 2rem);
    flex-shrink: 0;
    flex-grow: 0;
}

.content-modal {
    max-width: calc(100vw - 411px);

    &.v-enter-active,
    &.v-leave-active {
        transition: transform .5s ease,opacity .5s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        transform: translateX(-100vw);
        opacity: 0;
    }

    :deep(.content-modal-header) {
        display: flex;
        width: 100%;
        &.tabs {
          background-color: #000;
          color: #fff;
        }

        nav.header-tabs {
            flex-grow: 1;

            ul {
                display: flex;
            }

            ul button {
                color: #fff;
                font-size: 18px;
                margin: 0 6px;

                &.active {
                  color: var(--primary-light);
                }
            }
        }

    }

		:deep(nav.footer-tabs) {
				display: flex;
				gap: 15px;
				margin-bottom: 15px;
				z-index: 10;
				overflow-x: auto;
				margin-right: 10px;
				margin-left: 10px;

				.btn {
						background-color: #000;
						&:first-child {
								margin-right: auto;
						}

						&:last-child {
								margin-left: auto;
						}
				}
		}
}

.is-mobile {
    .page.floor {
        padding: 0;
				background-color: rgba(0,0,0,.86);

        .centered-content {
            flex-flow: column;
            gap: 0;
            max-width: none;
        }

        :deep(.floor-modal) {
            width: 100vw;
            border: none;
            max-width: none;
            border-radius: 0;
            background-color: transparent;
            background-image: none;
						padding-bottom: 100px;

						.modal-content {
								align-items: center;
						}

						.apt-price {
								margin: 5px 1px;
						}
        }

				:deep(.content-modal) {
						&.tabs:not(.inner-tabs) {
								top: 100px;
						}
						.content-modal-header {
								padding-right: 15px;
								padding-left: 15px;
						}
				}

    }
}

.fp-side-menu-wrapper + .fp-app-wrapper {
		.page.floor {
				padding: 0;
				align-items: stretch;

				:deep(.floor-modal) {
						border: none;
						background-color: transparent;
						background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
						backdrop-filter: blur(5px);
						border-radius: 0;
						height: 100svh;
						flex-shrink: 0;
				}

				.centered-content {
						gap: 0;
						max-width: none;
						align-items: stretch;
						overflow: hidden;
						height: 100svh;
				}

				:deep(.content-modal){
						border: none;
						border-radius: 0;
						max-width: none;
						display: flex;
						flex-flow: column;
						align-items: stretch;

						.content-modal-content {
								flex-grow: 1;
						}

						.content-modal-header ~ .content-modal-content .iframe {
								height: calc(100svh - 50px);
						}
				}
		}
}

.ltr {

		.floor-modal-wrapper {

				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(-500px);
				}
		}

		.content-modal {
				&.v-enter-from,
				&.v-leave-to {
						transform: translateX(100vw);
				}

				:deep(nav.footer-tabs) {
						.btn {
								background-color: #000;
								&:first-child {
										margin-right: 10px;
										margin-left: auto;
								}

								&:last-child {
										margin-left: 10px;
										margin-right: auto;
								}
						}
				}
		}
}
</style>
