<template>
    <div class="page area fade-in">
        <transition appear>
            <div class="area-modal">
                <area-page-tab v-if="currentTab" :content-page="currentTab"></area-page-tab>
                <div v-if="tabs && tabs.length > 1" class="areas-tabs">
                  <ul class="list-menu">
                    <li v-for="(tab, tabIndex) in tabs" :key="tabIndex">
                      <a href="javascript:" @click="changeTab(tab)">
                        {{tab?.post_title}}
                      </a>
                    </li>
                  </ul>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import AreaPageTab from "@/pages/AreaPageTab.vue";

export default {
    name: "AreaPage",
    components: {
      AreaPageTab,
    },
    computed: {
        tabs(){
          const menuItems = [];
          menuItems.push(this.contentPage);
          if(this.contentPage && this.contentPage.parent){
            menuItems.push(this.contentPage.parent);
          }
          if(this.contentPage && this.contentPage.children && this.contentPage.children.length){
            this.contentPage.children.forEach(child => {
              menuItems.push(child);
            });
          }
          if(this.contentPage && this.contentPage.siblings && this.contentPage.siblings.length){
            this.contentPage.siblings.forEach(sibling => {
              menuItems.push(sibling);
            });
          }
          // sort by ID
          menuItems.sort((a, b) => a.ID - b.ID);
          return menuItems;
        },
        ...mapGetters(['contentPage', 'isMobile', 'currentProject','currentProjectId', 'globalLoading'])
    },
    data() {
        return {
            currentTab: null
        }
    },
    watch: {
      tabs(newVal){
        if(newVal){
          if (!this.currentTab && newVal.length > 0) {
            this.currentTab = newVal[0];
          }
        }
      }
    },
    methods: {
        changeTab(tab){
          this.currentTab = null;
          setTimeout(() => {
            this.currentTab = tab;
          }, 200);
        },
        async loadPost(postId) {
          this.loading = true
          if (postId) {
            await this.$store.dispatch('loadContent', { postId, type: 'area' })
          }
          this.loading = false
        }
    },
    mounted() {
      console.log({
        tabs: this.tabs,
        contentPage: this.contentPage
      })
    },
    beforeRouteEnter(to, from, next) {
      const postId = to.params.postId
      next(vm => {
        vm.loadPost(postId)
      })
    },
    beforeRouteUpdate(to, from, next) {
      const postId = to.params.postId
      this.loadPost(postId)
      next()
    },
    beforeRouteLeave() {
      this.$store.commit('currentContentId', null)
    }
}
</script>

<style scoped lang="scss">
.page.area {
    display: flex;
    align-items: center;
    justify-content: center;
}

.area-modal {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 87.46%, #000000 93.93%);

    //border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    flex-flow: column;
    overflow: hidden;

    &.v-enter-active,
    &.v-leave-active {
        transition: opacity .8s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        opacity: 0;
    }

    .close-btn {
        position: absolute;
        top: 10px;
        left: 5px;
    }

    .areas-tabs {
      min-height: 35px;
      z-index: 4;
      position: relative;
      background-color: #000;

      .list-menu {
        display: flex;
        gap: 10px;
        padding: 15px 0;
        justify-content: center;

        li a {
          min-height: 80px;
          display: flex;
          align-items: center;
          min-width: 80px;
          border: solid 1px #fff;
          border-radius: 100%;
          justify-content: center;
        }
      }
    }

}

.is-mobile {
    .page.area {
      justify-content: start;
      align-items: start;
    }
    .area-modal {
        display: flex;
        flex-flow: column;
        max-width: none;
        border-radius: 0;
        border: none;
        height: calc(100svh - 65px);
    }

    .areas-tabs {
      position: absolute;
      bottom: 75px;
      left: 0;
      right: 0;
      background-color: #00000073;
      .list-menu {
        padding: 10px;
        overflow-x: auto;

        li a {
          font-size: 14px;
          min-width: 60px;
          min-height: 60px;
        }
      }
    }
}

.fp-side-menu-wrapper+.fp-app-wrapper {
    .page.area {
        height: 100%;
    }
}
</style>
