<template>
    <div class="stages">
        <template v-for="(stage, index) in stages" :key="index">
            <div tabindex="1" role="button" :aria-label="stage.label" @click="$emit('stage-selected', stage)" class="stage" :class="[stage.status]">
								<svg v-if="stage.status === 'full'" viewBox="0 0 19.33 19.33"><defs><linearGradient id="linear-gradient" x1="9.66" y1="18.61" x2="9.66" y2="-.71" gradientTransform="translate(0 18.61) scale(1 -1)" gradientUnits="userSpaceOnUse"><stop offset="0" :stop-color="light"/><stop offset="1" :stop-color="dark"/></linearGradient></defs><circle cx="9.66" cy="9.66" r="9.66" style="fill:url(#linear-gradient);"/></svg>
								<svg v-else-if="stage.status === 'current'" viewBox="0 0 19.4 19.4"><defs><linearGradient id="linear-gradient" x1="9.7" y1="824.8" x2="9.7" y2="838.6" gradientTransform="translate(0 -822)" gradientUnits="userSpaceOnUse"><stop offset="0" :stop-color="light"/><stop offset="1" :stop-color="dark"/></linearGradient></defs><circle cx="9.7" cy="9.7" r="9.7" style="fill:#000;"/><circle cx="9.7" cy="9.7" r="9.3" :style="{fill:'none', stroke:light, 'stroke-width':'.71px'}"/><circle cx="9.7" cy="9.7" r="6.9" style="fill:url(#linear-gradient);"/></svg>
                <fp-icon v-else symbol="step-circle-empty"></fp-icon>
                <div class="label">{{stage.label}}</div>
            </div>
            <div class="stage-gap"></div>
        </template>
    </div>
</template>

<script>
import FpIcon from "@/components/FpIcon";
import {mapGetters} from "vuex";
export default {
    name: "ProjectStages",
    components: {FpIcon},
    props: {
        project: {
            type: Object
        }
    },
    computed: {
        stages(){
            if(this.project && this.project.stages){
                return this.project.stages
            }
            return null;
        },
				light(){
						return this.colors.primary_lighter
				},
				dark(){
						return this.colors.primary_darker
				},
				...mapGetters(['colors'])
    }
}
</script>

<style scoped lang="scss">
.stages {
    display: flex;
    flex-flow: row nowrap;
    position: relative;

    .stage {
        white-space: nowrap;
        text-align: center;
        position: relative;
        z-index: 2;
        cursor: pointer;
				svg {
						display: inline-block;
						width: 25px;
						height: 25px;
				}
    }

    .stage-gap {
        width: 1rem;
        position: relative;
    }

    .stage-gap::after {
        content: "";
        position: absolute;
        height: 1px;
        background-color: #959595;
        width: 6.5rem;
        top: 11px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
    }

    .stage.full + .stage-gap::after {
        background-color: var(--primary-dark);
    }

    .stage-gap:last-child::after {
        display: none;
    }

    .label {
        margin-top: 5px;
        width: 7rem;
    }

    /*&::after {
        content: "";
        height: 1px;
        position: absolute;
        width: 85%;
        background-color: #959595;
        top: 11px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 7px;
        z-index: 1;
    }*/
}
</style>
