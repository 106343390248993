<template>
	<div class="language-switcher" v-if="languages && languages.length > 1">
		<template v-for="(lang, langIndex) in languages" :key="langIndex">
			<a class="circledIcon  cursor-pointer" v-if="locale !== lang.value" href="javascript:"
				@click.stop="setLocale(lang.value)">
				<span v-if="lang && lang.value">{{ lang.value.toUpperCase() }}</span>
				<!-- <img v-if="lang.value === 'he'" src="../assets/flags/he.svg" :alt="lang.label">
						<img v-else-if="lang.value === 'en'" src="../assets/flags/en.svg" :alt="lang.label">
						<img v-else-if="lang.value === 'fr'" src="../assets/flags/fr.svg" :alt="lang.label"> -->
			</a>
		</template>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
	name: "LanguageSwitcher",
	computed: {
		languages() {
			if (this.settings && this.settings.languages) {
				return this.settings.languages
			}
			return null
		},
		...mapGetters(['settings', 'locale'])
	},
	methods: {
		...mapActions(['setLocale'])
	},
	mounted() {
	}
}
</script>

<style scoped lang="scss">
.language-switcher {
	display: flex;
	justify-content: center;
	gap: 10px;
	

	.circledIcon {
		width: 2rem !important;
		height: 2rem !important;
		background-color: rgba(61, 61, 61, 1);
		font-size: .7rem;
	}

	img {
		width: 20px;
	}
}
</style>
