<template>
    <div class="specs-wrapper">
        <!-- <h2 class="title">{{content.label}}</h2> -->
        <div v-if="viewType == 'big'" class="headerBackgroundText gradientText mb-3">SPECIFICATIONS</div>
        <!-- <fp-tabs v-model="tab" :tabs="tabs"></fp-tabs> -->


                <div class="specifications scrollable-y" v-if="content.specifications">
                    <div class="specification hover-primary" v-for="(spec, specI) in content.specifications" :key="specI">
                        <fp-icon
                                v-if="spec.animated_icon"
                                :animated="spec.animated_icon"
                                :state="spec.animated_icon_state"
                                :style="{color: (spec.animated_icon_color || '#fff')}"
                                target=".specification"
                        ></fp-icon>
                        <img v-else-if="spec.icon" :src="spec.icon" :alt="spec.title">
                        <div class="spec-title">
                            {{spec.title}}
                        </div>
                        <div class="spec-excerpt" v-html="spec.excerpt"></div>
                    </div>
                </div>
 
  
        <!-- <template v-for="(tabContent, tabContentI) in content.tabs" :key="tabContentI">
            <div class="tab-content" v-if="tab === (tabContentI+'')">
                <div class="specifications" v-if="tabContent.specifications">
                    <div class="specification hover-primary" v-for="(spec, specI) in tabContent.specifications" :key="specI">
                        <fp-icon
                                v-if="spec.animated_icon"
                                :animated="spec.animated_icon"
                                :state="spec.animated_icon_state"
                                :style="{color: (spec.animated_icon_color || '#fff')}"
                                target=".specification"
                        ></fp-icon>
                        <img v-else-if="spec.icon" :src="spec.icon" :alt="spec.title">
                        <div class="spec-title">
                            {{spec.title}}
                        </div>
                        <div class="spec-excerpt" v-html="spec.excerpt"></div>
                    </div>
                </div>
            </div>
        </template> -->
    </div>
</template>

<script>
// import FpTabs from "@/components/FpTabs";
import FpIcon from "@/components/FpIcon.vue";
export default {
    name: "BuildingSpecs",
    components: {FpIcon},
    props: {
        content: {
            required: true
        },
        viewType: {
            required: false,
            default: "big"
        }
    },
    // watch: {
    //     content(newVal){
    //         this.loadTabs(newVal)
    //     }
    // },
    data(){
        return {
            tabs: {},
            tab: null
        }
    },
    methods: {
        // loadTabs(content){
        //     if(content){
        //         if(content.acf_fc_layout && content.acf_fc_layout === 'specifications'){
        //             if(content.tabs){
        //                 console.log("content.tabs", content.tabs)
        //                 content.tabs.forEach((t, ti) => {
        //                     if(this.tab === null){
        //                         this.tab = `${ti}`
        //                     }
        //                     this.tabs[`${ti}`] = t.label
        //                 })
        //             }
        //         }
        //     }
        // }
    },
    mounted() {
        if(this.content){
            console.log("this.content", this.content)
            // this.loadTabs(this.content)
        }
    }
}
</script>

<style scoped lang="scss">
.specs-wrapper {
    height: 73vh;
    margin-top: 14rem;

    .title {
        font-size: 1.875rem;
        font-weight: 700;
        text-align: center;
        margin: 1.875rem 0 3.125rem;
    }
    .headerBackgroundText {

        left: 7rem;
        top: -4rem;

    }
    .specifications {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 15px auto;
        width: 100%;
        max-width: 995px;
        max-height: 50vh;
    }

    .specification {
        flex-basis: calc(30%);  /* Here, 10px is for the margin. Adjust as per requirement */
        box-sizing: border-box;
        height: auto !important;
        background-color: rgba(179, 179, 179, 0.2);
        padding: 20px 16px 20px;
        margin: 7px;
        box-sizing: border-box;
        border: 1px solid transparent;
        transition: border-color 0.3s ease;

        .spec-title {
            font-size: 1.325rem;
            margin: 10px 0;
            font-weight: bold;
        }

        .spec-excerpt {
            white-space: break-spaces;
            margin-left: 3rem;
            line-height: 1.3rem;
        }
    }
}

.is-mobile {
    .specs-wrapper {
        height: auto;
        margin-top: 0rem;
        padding-top: 1rem;
        padding-bottom: 5rem;
        .specifications{
            margin-top: 1rem;
            max-height: 83vh;
        }
        .specification{
            flex-basis: 100%; 
            
        }
    }
}
</style>
