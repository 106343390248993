import axios from "axios";
import store from "@/store";

let baseUrl = process.env.VUE_APP_BASE_URL+process.env.VUE_APP_API_PATH
const appUrl = `${window.location.protocol}//${window.location.host}/`

if(window.siteId){
    baseUrl = `${baseUrl}${window.siteId}/`
}

export {baseUrl, appUrl};

const http = axios.create({baseURL: baseUrl});

http.interceptors.request.use((config) => {
    if(store.state.accessToken){
        config.headers.Authorization = `Bearer ${store.state.accessToken}`
    }
    /*if(store.state.user && store.state.user.ID){
        config.headers['fp-ui'] = store.state.user.ID
    }*/
    /*let locale = 'he'
    if (i18n.mode === 'legacy') {
        locale = i18n.global.locale
    } else if(i18n.global.locale?.value) {
        locale = i18n.global.locale.value
    }
    if(!config.params){
        config.params = {}
    }
    config.params['wpml_language'] = locale*/
    return config
},(error) => {
    store.commit('globalLoading', 0)
    return error;
})

http.interceptors.response.use((response) => {
    return response
}, (error) => {
    store.commit('globalLoading', 0)
    console.warn(error)
    return error.response
})

export default http
