<template>
    <div class="fullScreenFadedBlack">
        <div class="registrationProcess">
            <div class="header flex justify-between">
                <span>{{ $t('יצירת קשר') }}</span>
                <close-btn @click="$store.commit('toggleApartmentRegistrationModal')"></close-btn>
            </div>
            <!-- <div v-if="signatureIsMandatory ? currentStepIndex < 3 : currentStepIndex < 2"> -->
            <div class="stages-wrapper">
                <Steps :steps="steps"></Steps>
            </div>
            <div class="stages-content">
                <div v-if="currentStepIndex == 4 && signatureIsMandatory || !signatureIsMandatory && currentStepIndex == 3">
                    <div class="registrationMessage">
                        {{ $t('!פרטיך התקבלו בהצלחה') }}
                    </div>
                </div>
                <div :class="{ disabled: currentStepIndex >= 2 }">
                    <fp-input v-model="formData['name']" name="name" :disabled="loading" :placeholder="$t('שם')"
                        required></fp-input>
                    <br>
                    <fp-input v-model="formData['phone']" name="phone" :disabled="loading" :placeholder="$t('טלפון')"
                        required></fp-input>
                    <br>
                    <fp-input v-model="formData['email']" name="email" :disabled="loading" :placeholder="$t('אימייל')"
                        required></fp-input>
                    <div class="terms_approval flex align-center">
                        <fp-checkbox v-model="formData.terms_approval" name="terms_approval" :inline="false"></fp-checkbox>
                        <p>{{ $t('הנני מאשר/ת קבלת פניות שיווקיות לדואל/טלפון/סמס המידע של חברתנו') }}</p>

                    </div>

                </div>


            </div>
            <div class="loadingSpinner" v-if="loading && currentStepIndex < 3">
                <AnimatedCircles :animate="loading" :color="colors.primary" :radius="20" :circleSize="48" />

            </div>
            <div class="bottomButtons flex justify-between">
                <fp-icon-button @click="send" class="btn btn-secondary-outline" symbol="phone-ring"
                    :iconBackGroundColor="colors.primary" :title="$t('הרשמה')" :height="'2.8rem'">
                    <span>{{ $t('שליחת פרטים') }}</span>
                </fp-icon-button>
                <div :class="{ disabled: !detailesSent }" v-if="signatureIsMandatory">
                    <fp-icon-button @click="setCurrentStep(3)" class="btn btn-secondary-outline" symbol="phone-ring"
                        :iconBackGroundColor="colors.primary" :title="$t('הרשמה')" :height="'2.8rem'">
                        <span>{{ $t('שריון דירה') }}</span>
                    </fp-icon-button>

                </div>
            </div>
            <transition appear>
                <div class="signatureArea p-2 flex justify-between" v-if="currentStepIndex >= 3">
                    <div class="canvasWrapper">
                        <SignatureCanvas ref="signatureCanvas" />

                    </div>
                    <div class="sendButtonWrapper" :style="{
                        width: sendButtonSize,
                        height: sendButtonSize,
                    }" @click="send" :disabled="loading">
                        <AnimatedCircles :animate="loading" :color="colors.primary" />

                        <span :class="{ disabled: loading }">{{ $t('שלח') }}</span>
                    </div>
                    <!-- <div class="registerButton">
                            <FpBeautifullButton :size="'8rem'" :text="$t('הרשמה')" @click="saveSignature" />
    
                        </div> -->
                </div>

            </transition>

            <!-- </div> -->
            <!-- <div v-else>
                <div class="registrationMessage">
                    {{ $t('פרטיך התקבלו בהצלחה') }}
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>
import Steps from "@/components/Steps";
import FpInput from "@/components/FpInput";
import SignatureCanvas from "@/components/Signature";
import FpIconButton from "@/components/buttons/FpIconButton"
import FpCheckbox from "@/components/buttons/FpCircleCheckBox"
// import FpBeautifullButton from "@/components/buttons/FpBeautifullButton";
import CloseBtn from "@/components/buttons/CloseBtn";
import AnimatedCircles from "@/components/AnimatedCircles.vue"
import { mapGetters } from "vuex";
export default {
    name: "RegistrationProcess",
    props: {

    },
    components: {
        Steps,
        FpInput,
        FpIconButton,
        FpCheckbox,
        SignatureCanvas,
        // FpBeautifullButton,
        CloseBtn,
        AnimatedCircles
    },
    data() {
        return {
            steps: [
                { label: 'בוחרים דירה', status: 'full' },
                { label: 'ממלאים פרטים', status: 'current' },
                { label: 'משריינים דירה', status: '' },
                { label: 'הדירה משוריינת ל48 שעות', status: '' },
            ],
            sendButtonSize: '120px',
            currentStepIndex: 1,
            formData: {
                terms_approval: false
            },
            alertMessage: null,
            loading: false,
            detailesSent: false
        }
    },
    methods: {
        setCurrentStep(stepIndex) {
            this.currentStepIndex = stepIndex
            this.steps.forEach((step, index) => {
                if (index == stepIndex) {
                    step.status = 'current'
                } else step.status = ''
            })

        },
        async saveSignature() {
            this.formData.signature = this.$refs.signatureCanvas.getSignatureData();
            await this.$http.post('contact/signature', this.formData)
            // const { data, status } = await this.$http.post('contact/signature', this.formData)
        },
        formReset() {
            this.formData = {
                terms_approval: false
            }
            // if(this.fields){
            //     this.fields.forEach((field) => {
            //         this.formData[field.name] = "";
            //     })

            // }
        },
        async send() {
            // if(this.terms_approval_mandatory && this.terms_link && this.terms_link.url && !this.formData.terms_approval){
            //         alert(this.$t('יש לאשר את התקנון'))
            //         return
            // }
						let openTransaction = false;
						if(this.$refs.signatureCanvas){
								this.formData.signature = this.$refs.signatureCanvas.getSignatureData();
								openTransaction = true;
						}

            this.loading = true
            this.formData.href = window.location.href
            // this.loading = true
            const { data, status } = await this.$http.post('contact', this.formData)
            this.loading = false
            if (status === 200 && data.mailSent) {
                this.detailesSent = true
                // this.formReset()
                this.loading = false
                if (this.signatureIsMandatory && this.currentStepIndex == 3) {
                    this.alertMessage = this.$t('פרטיך התקבלו בהצלחה!');
                    setTimeout(() => {
                        this.alertMessage = null;
                        this.$store.commit('toggleApartmentRegistrationModal')
                    }, 3000)

										if(openTransaction && window.location.href.includes('lands.olyo.io')){
												window.open('https://uiservices.aureavia.com/hosted/?merchantID=5907304&url_redirect=&url_notify=&trans_comment=&trans_refNum=&Brand=&trans_installments=1&amount_options=10-5000&ui_version=6&trans_type=0&trans_amount=100&trans_currency=USD&disp_paymentType=&disp_payFor=WIZR&disp_recurring=0&disp_lng=en-gb&disp_mobile=auto&signature=CXWXBXn4ibJdlCN8h3h3dADNxEaeTQHxuLS8yin%2fU20%3d', '_blank');
										}
                }
            }
						this.setCurrentStep(this.currentStepIndex + 1);
        },

    },
    computed: {

        ...mapGetters(['colors', 'signatureIsMandatory'])
    }



}
</script>

<style scoped lang="scss">
.fullScreenFadedBlack {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 31;
}

.registrationProcess {

    width: 40rem;
    transition: .3s all;
    position: fixed;
    left: 50vw;
    top: 8vh;
    background: black;
    transform: translateX(-50%);
    transform-origin: top;
    z-index: 32;

    .registrationMessage {
        position: absolute;
        bottom: -16rem;
        z-index: 1;
        left: 1rem;
        font-size: 1rem;
        font-style: bold;
        margin: auto;
    }

    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    .header {
        border-radius: 0.3125rem 0.3125rem 0rem 0rem;
        background: rgba(53, 53, 53, 0.90);
        font-size: 1.625rem;
        padding: 1rem 2rem;
        font-weight: 700;

    }

    .sendButtonWrapper {
        position: relative;
        cursor: pointer;


    }

    .loadingSpinner {
        position: absolute;
        left: 4rem;
        top: 23rem;
        z-index: 10;
    }

    .sendButtonWrapper span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }

    .stages-wrapper {
        width: 100%;
        background: #1C1C1C;

    }

    .stages-content {
        position: relative;
        padding: 1rem 2rem;
        border-radius: 0.3125rem;
        background: #202020;
        z-index: 1;
    }

    .form-group.input-underline {
        width: 100%;
        margin-bottom: 2rem;
    }

    .stages {
        padding-top: 2rem;
        padding-bottom: 1rem;
    }

    .bottomButtons {
        position: relative;
        width: 100%;
        background: #202020;
        padding: 1rem 2rem;
        z-index: 1;

        button {
            width: 17rem;
            height: 2.8rem;
            font-weight: 700;
            font-size: 0.9375rem;

        }
    }

    .terms_approval {
        p {
            margin-right: 1rem;
            margin-left: 1rem;
            max-width: 21rem;
            font-size: .9rem;
        }
    }

    .canvasWrapper {
        width: 400px;
        height: 150px;
    }

}

.signatureArea {
    position: relative;
    background: #3C3C3C;
    z-index: 0;
    border-radius: 0 0 0.3125rem 0.3125rem;

    canvas {
        border-radius: 0.3125rem !important;

    }

    &.v-enter-active,
    &.v-leave-active {
        transition: all .3s ease;
    }

    &.v-enter-from,
    &.v-leave-to {
        margin-top: -16rem;
    }

}

.ltr {
    .loadingSpinner {
        position: absolute;
        left: auto;
        right: 4rem;
        top: 23rem;
        z-index: 10;
    }
    .stages-content {
        z-index: 1;
    }
    .registrationMessage {

        left: auto;
        right: 1rem;
    }
}

.is-mobile {
    .registrationProcess {

        width: 100vw;
        left: 0;
        top: 0;
        transform: translateX(-0%);
        z-index: 32;


        .header {
            border-radius: 0.3125rem 0.3125rem 0rem 0rem;
            padding: 1rem 1rem;
            font-weight: 700;

        }





        .form-group {
            width: 100%;
            margin-bottom: 1.1rem;

            input {
                width: 100% !important;
            }
        }

        .stages {
            padding-top: 2rem;
            padding-bottom: 1rem;
        }

        .bottomButtons {
            position: relative;
            width: 100%;
            background: #202020;
            padding: 1rem 1rem;
            z-index: 1;

            button {
                width: 43vw;
                height: 2.8rem;
                font-weight: 700;
                font-size: .8rem;
                margin: 0 .2rem;

            }
        }

        .terms_approval {
            p {
                margin-right: 1rem;
                max-width: 21rem;
                font-size: .9rem;
            }
        }

    }

    .signatureArea {

        flex-flow: column;
        align-items: center;

        gap: 0.2rem;

        canvas {
            border-radius: 0.3125rem !important;


        }

        .canvasWrapper {
            width: 85vw;
            height: 130px;
        }

        &.v-enter-active,
        &.v-leave-active {
            transition: all .3s ease;
        }

        &.v-enter-from,
        &.v-leave-to {
            margin-top: -16rem;
        }

    }
}</style>
