<template>
		<div v-if="apt" class="apt-box">
				<div
						v-if="apt.type_thumbnail && showThumbnail"
						class="apt-thumbnail"
						:class="{clickable: aptIsClickable(apt)}"
						tabindex="1"
						role="button"
						@click="goToApartmentPage"
				>
						<apartment-fav-button :apt-id="apt.id"></apartment-fav-button>
						<img :src="apt.type_thumbnail" :alt="apt.name">
				</div>
				<apartment-availability :available="apt.available"></apartment-availability>
				<div class="apt-details-wrapper">
						<div v-if="apartment_name_format" class="apt-title" :class="{clickable: aptIsClickable(apt)}" tabindex="1" role="button" @click="goToApartmentPage">
								<apartment-title :apartment="apt" :show-favs-button="false"></apartment-title>
						</div>
						<apartment-features-list
								layout="dashed"
								:apartment="apt"
								:show-icons="false"
								location="block"
						></apartment-features-list>
						<div v-if="showCompare" class="apt-box-footer">
								<hr>
								<fp-checkbox v-if="compareEnabled" v-model="aptComp[apt.id]">{{$t('השוואה')}}</fp-checkbox>
						</div>
				</div>
		</div>
</template>

<script>
import {mapGetters} from "vuex";
import FpCheckbox from "@/components/buttons/FpCheckbox";
import ApartmentFavButton from "@/components/buttons/ApartmentFavButton";
import ApartmentAvailability from "@/components/apartment/ApartmentAvailability";
import ApartmentFeaturesList from "@/components/ApartmentFeaturesList.vue";
import ApartmentTitle from "@/components/apartment/ApartmentTitle.vue";

export default {
		name: "AptBox",
		components: {ApartmentTitle, ApartmentFeaturesList, ApartmentAvailability, ApartmentFavButton, FpCheckbox},
    emits: ['apartment-clicked'],
		props: {
				apt: {
						required: false,
						default: null
				},
				showDetails: {
						required: false,
						type: Boolean,
						default: true
				},
				navigateOnClick: {
						required: false,
						type: Boolean,
						default: false
				},
				showCompare: {
						required: false,
						type: Boolean,
						default: true
				},
				showThumbnail: {
						required: false,
						type: Boolean,
						default: true
				}
		},
		computed: {
				apartment_name_format(){
						if(this.filterOptions && this.filterOptions.apartment_name_format){
								return this.$store.getters.settings.apartments_filter.options.apartment_name_format
						}
						return null
				},
				settings(){
						if(this.$store.getters.settings && this.$store.getters.settings.apartments_filter){
								return this.$store.getters.settings.apartments_filter
						}
						return {}
				},
				...mapGetters(['filterOptions', 'apartmentPageEnabled','apartmentsCompare', 'currentProjectId','compareEnabled'])
		},
		watch: {
				aptComp: {
						handler(newVal){
								this.$store.commit('apartmentsCompare', newVal)
						},
						deep: true
				},
				apartmentsCompare(newVal){
						this.aptComp = newVal
				}
		},
		data(){
				return {
						aptComp: {}
				}
		},
		methods: {
				goToApartmentPage(){
						if(this.aptIsClickable(this.apt)){
								if(this.navigateOnClick){
										this.$router.push({name: 'apartment', params: {projectId: this.currentProjectId, apartmentId: this.apt.id}})
								}else{
										this.$emit('apartment-clicked', this.apt)
								}
						}
				},
				aptIsClickable(apt){
						return this.apartmentPageEnabled && apt.type_show_page
				},
				aptTitle(apt){
						let titleTemplate = this.apartment_name_format
						if(!titleTemplate){
								return null
						}
						const variables = [
								"דירה",
								"טיפוס",
								"בניין",
								"חדרים",
								"קומה",
								"שכונה",
								"מגרש",
						]
						variables.forEach((variable) => {
								let text = null;
								switch (variable){
										case "דירה":
												text = apt.title
												break
										case "טיפוס":
												text = apt.type
												break
										case "בניין":
												text = apt.type_building ? apt.type_building.name : null
												break
										case "חדרים":
												text = apt.type_rooms
												break
										case "קומה":
												text = apt.floor
												break
										case "שכונה":
												text = apt.type_quarter ? apt.type_quarter.name : null
												break
										case "מגרש":
												text = apt.type_plot ? apt.type_plot.name : null
												break
								}

								if(text !== null){
										text = `${text}`
										const rex = new RegExp(`\\[${variable}]`, 'g')
										titleTemplate = titleTemplate.replace(rex,   `${text}`)
								}
						})
						return titleTemplate
				}
		},
		mounted() {
				this.aptComp = this.apartmentsCompare
		}
}
</script>

<style scoped lang="scss">
.apt-box {
		width: 210px;
		position: relative;
		border: solid 1px #8B8985;
		border-radius: 3px;

		:deep(ul.apt-features-list) {
			padding: 0 0.812rem;
		}

		:deep(.apt-availability) {
				position: absolute;
				top: 10px;
				right: 0;
				z-index: 1;
				margin: 0;

				+ .apt-details-wrapper {
						margin-top: 35px;
				}
		}

		.apt-thumbnail ~ .apt-details-wrapper {
				margin-top: 0;
		}
}

.apt-box-footer {
		padding: 0 10px 10px;
}

.apt-box-footer hr {
		border-color: #5E5E5C;
}


.apt-thumbnail {
		border-bottom: solid 1px #8B8985;
		position: relative;

		:deep(span.fav-button-wrapper) {
				position: absolute;
				left: 5px;
				top: 5px;
				background-color: transparent;
				background-image: linear-gradient(to left, rgb(0 0 0 / 80%) 50%,rgb(0 0 0 / 90%) 100%);
				backdrop-filter: blur(5px);
				width: 30px;
				height: 30px;
				padding: 5px 0;
				border-radius: 30px;
		}

		&.clickable {
				cursor: pointer;
		}

		:deep(img) {
				max-width: 100%;
				height: 125px;
				object-fit: cover;
				object-position: center;
				display: block;
		}
}

.apt-details-wrapper {
		padding: 0.5rem 0.812rem;
}

.apt-title,.apt-details {
		padding: 0 0.812rem;
		font-size: 1rem;
		line-height: 1.2;
}

.apt-title {
		font-size: 1.125rem;
		font-weight: 700;

		&.clickable {
				cursor: pointer;
		}
}

.is-mobile {
		.apt-box {
				display: flex;
				flex-flow: row nowrap;
				width: auto;
		}

		.apt-thumbnail {
				border-bottom: none;

				:deep(img){
						max-width: none;
						width: 145px;
						height: 100%;
				}
		}
}
</style>
