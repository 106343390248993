<template>
    <ul class="apt-features-list" :class="[`layout-${layout}`]">
				<!--<li v-if="apartment.type_building"><fp-icon symbol="buildings-3d"></fp-icon><span class="param-name">{{$t('בניין')}}</span><span class="param">{{apartment.type_building.name}}</span></li>-->
        <template v-if="useApartmentFieldsOrder && apartmentFieldsOrder && apartmentFieldsOrder.length">
						<template v-for="(detail, detailIndex) in apartmentFieldsOrder" :key="detailIndex">
								<li class="uadc" v-if="aptDetail(detail.field)">
										<!--<img v-if="detail.icon && showIcons" :src="detail.icon" :alt="detail.label">-->
										<span
												class="param-name"
												:title="detail.title"
												:aria-label="detail.title"
										>{{ detail.title }}</span>
										<span class="param">{{ aptDetail(detail.field) }}</span>
								</li>
						</template>
				</template>
				<template v-else>
						<li v-if="apartmentsFilter.floors.show && apartment.type_floors">
								<fp-icon v-if="showIcons" symbol="roller"></fp-icon>
								<span class="param-name">{{apartmentsFilter.floors.label ||  $t('קומה')}}</span><span class="param">{{apartment.type_floors}}</span>
						</li>
						<li v-if="apartmentsFilter.rooms.show && apartment.type_rooms">
								<fp-icon v-if="showIcons" symbol="open-door"></fp-icon>
								<span class="param-name">{{apartmentsFilter.rooms.label || $t('חדרים')}}</span><span class="param">{{apartment.type_rooms}}</span>
						</li>
						<li v-if="apartmentsFilter.direction.show && apartment.type_direction">
								<fp-icon v-if="showIcons" symbol="swirled-arrow-left"></fp-icon>
								<span class="param-name">{{apartmentsFilter.direction.label || $t('כיוון')}}</span><span class="param">{{apartment.type_direction}}</span>
						</li>
						<li v-if="showApartmentSquareMeter && hasApartmentTypeDimensions">
								<fp-icon v-if="showIcons" symbol="field-size"></fp-icon>
								<span class="param-name">{{apartmentsFilter.apartment_sm.label || $t('שטח דירה')}}</span><span class="param">{{apartment.type_dimensions.apartment_sm}}</span> <span>{{apartmentsFilter.apartment_sm.unit_of_measure}}</span>
						</li>
						<li v-if="showPorchSquareMeter && hasPorchTypeDimensions">
								<fp-icon v-if="showIcons" symbol="porch"></fp-icon>
								<span class="param-name">{{apartmentsFilter.porch_sm.label || $t('שטח מרפסת')}}</span><span class="param">{{apartment.type_dimensions.porch_sm}}</span> <span>{{apartmentsFilter.porch_sm.unit_of_measure}}</span>
						</li>
						<template v-if="apartment.more_details && apartment.more_details.length">
								<li v-for="(detail, dIndex) in apartment.more_details" :key="dIndex">
										<img v-if="detail.icon && showIcons" :src="detail.icon" :alt="detail.label">
										<span class="param-name">{{detail.label}}</span><span class="param">{{detail.value}}</span>
								</li>
						</template>
				</template>
    </ul>
</template>

<script>
import FpIcon from "@/components/FpIcon";
import {mapGetters} from "vuex";
import numeral from "numeral"

export default {
    name: "ApartmentFeaturesList",
    components: {FpIcon},
    props: {
        apartment: [Object],
				layout: {
						default: 'piped',
						required: false,
						type: String
				},
				showIcons: {
						default: true,
						type: Boolean,
						required: false
				},
				location: {
						default: 'block',
						type: String,
						required: false
				}
    },
		computed: {
				hasApartmentTypeDimensions(){
						return this.apartment && this.apartment.type_dimensions && this.apartment.type_dimensions.apartment_sm
				},
				hasPorchTypeDimensions(){
						return this.apartment && this.apartment.type_dimensions && this.apartment.type_dimensions.porch_sm
				},
				showApartmentSquareMeter(){
						return this.apartmentsFilter && this.apartmentsFilter.apartment_sm && this.apartmentsFilter.apartment_sm.show
				},
				showPorchSquareMeter(){
						return this.apartmentsFilter && this.apartmentsFilter.porch_sm && this.apartmentsFilter.porch_sm.show
				},
				aptMoreDetailsBySlug(){
						if(this.apartment && this.apartment.more_details){
								const md = {}
								this.apartment.more_details.forEach(mdItem => {
										if(mdItem.column_name && mdItem.value){
												md[mdItem.column_name] = mdItem.value
										}
								})
								return md
						}
						return null
				},
				apartmentsDetails(){
						if(this.apartmentDataView && this.apartmentDataView.apartments_details){
								return this.apartmentDataView.apartments_details
						}
						return null
				},
				importTableHeaders(){
						if(this.apartmentDataView && this.apartmentDataView.import_table_headers){
								return this.apartmentDataView.import_table_headers
						}
						return null
				},
				titlesBySlug(){
						if(this.importTableHeaders){
								const headers = {};
								Object.keys(this.importTableHeaders).forEach(ithKey => {
										const ith = this.importTableHeaders[ithKey]
										headers[ith.slug] = ith.title
								})
								return headers
						}
						return []
				},
				useApartmentFieldsOrder(){
						return this.apartmentFieldsOrder != null;
				},
				apartmentFieldsOrder(){
						return this.location === 'page' ? this.apartmentPageFieldsOrder : this.apartmentBlockFieldsOrder;
				},
				...mapGetters(['apartmentsFilter', 'apartmentDataView', 'apartmentBlockFieldsOrder', 'apartmentPageFieldsOrder'])
		},
		methods: {
				titleDisplay(slug){
						if(this.titlesBySlug && this.titlesBySlug[slug]){
								return this.titlesBySlug[slug]
						}
						switch (slug) {
								case 'plot':
										return 'מספר מגרש'
								case 'building':
										return 'בניין'
								case 'rooms':
										return 'חדרים'
						}
						return ''
				},
				aptDetail(field){
						if(this.apartment && this.apartment.display_fields && this.apartment.display_fields.length){
								const df = this.apartment.display_fields.find(f => f.field === field);
								if(df){
										return df.value;
								}
						}
						return ''
				},
				formatNumeric(value, slug){
						if(slug.indexOf('price') !== -1 || slug.indexOf('cost') !== -1 || slug.indexOf('fee') !== -1 || slug.indexOf('tax') !== -1){
								const num = numeral(value).format('0,0')
								return `₪${num}`
						}
						return value;
				}
		}
}
</script>

<style scoped lang="scss">
ul.apt-features-list {

		li {
				padding: 0.2rem 0;
				display: flex;
				align-items: stretch;
				flex-flow: row nowrap;
				gap: 0.9375rem;

				image {
						max-width: 23px;
						height: auto;
				}

				span.param-name {
					margin-left: .2rem;
					&::before {
						content: " ";
						height: 3px;
						width: 3px;
						margin-left: .5rem;
						display: inline-block;
						vertical-align: middle;
						background-color: #fff;
				}
				}

				.param {
						white-space: nowrap;

				}

				&.uadc {
						gap: .1rem;
						span.param-name {
							
						}
				}
		}

		&.layout-dashed {
				li {
						gap: 2px;

						span.param-name {
								width: auto;
						}

						.param::before {
								content: " - ";
								width: auto;
								height: auto;
								margin: 0 5px;
								background-color: transparent;
								color: #fff;
						}
				}
		}
}

.is-mobile{
	ul.apt-features-list {
		gap: .4rem;
		display: flex;
		align-items: center;
		flex-shrink: 1;
		justify-content: space-between;
		background: #1D1D1D;
		padding: 1rem;
		border-radius: 0.625rem 0.625rem 0rem 0rem;

		li {
				padding: 0.2rem 0.3rem;
				display: flex;
				align-items: center;
				flex-shrink: 1;
				flex-flow: row wrap;
				font-size: .8rem;
				gap: 0.1375rem;
				border-radius: 6.25rem;
				background-color: var(--grey-grey-3, #B3B3B1);
				border: 1px solid var(--grey-grey-3, #B3B3B3);

				image {
						max-width: 23px;
						height: auto;
				}

				span.param-name {
					margin-left: 0;
					&::before {
						display: none;
						content: " ";
						height: 3px;
						width: 3px;
						margin-left: .5rem;
			
						vertical-align: middle;
						background-color: #fff;
					}
				}

				.param {
						white-space: nowrap;

				}

				&.uadc {
						gap: .1rem;
						span.param-name {
							
						}
				}
		}

		&.layout-dashed {
				li {
						gap: 2px;

						span.param-name {
								width: auto;
						}

						.param::before {
								content: "";
								width: auto;
								height: auto;
								margin: 0 5px;
								background-color: transparent;
								color: #fff;
						}
				}
		}
}
}
</style>
