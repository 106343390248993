<template>
    <div v-if="isSideMenu && !apartmentOnly" class="fp-side-menu-wrapper">
            <side-menu></side-menu>
    </div>
<div class="fp-app-wrapper" :class="{'is-mobile': isMobile,'is-tablet': isTablet,'is-desktop': isDesktop, 'ctrl-360': ctrl360}">
    <mobile-header></mobile-header>
    <projects-pane v-if="!apartmentOnly"></projects-pane>
    <stages-pane v-if="stagesPaneShow && !apartmentOnly"></stages-pane>
    <div class="main" @click="closeMobileMenu">
        <div class="content" :style="{height: contentHeight}">
            <router-view></router-view>
        </div>
    </div>
    <contact-modal></contact-modal>
    <mobile-buttons v-if="isMobile && !apartmentOnly"></mobile-buttons>
    <menu-bar v-if="hasBottomBar && !apartmentOnly"></menu-bar>
            <template v-else>
                    <div id="menuBarTop"></div>
            </template>
    <floating-contact-toggle v-if="showFloatingContactButton && !isSideMenu"></floating-contact-toggle>
    <floating-whatsapp-button v-if="isMobile && hasWhatsappButton"></floating-whatsapp-button>
    <app-bg></app-bg>
    <share-dialog></share-dialog>
    <div v-if="imageCanvas && imageCanvas.src" class="canvas-modal">
        <div class="canvas-modal-body">
            <close-btn @click="$store.dispatch('closeImageEditor')"></close-btn>
            <image-canvas :image-src="imageCanvas.src"></image-canvas>
        </div>
    </div>
            <accessibility-modal></accessibility-modal>
            <div v-if="!isSideMenu" class="credits">powered by <a href="https://studioy.io" target="_blank">studioY.io</a></div>
</div>
    <lottie-loader></lottie-loader>
    <g-dialog-root></g-dialog-root>
</template>

<script>
import MenuBar from "@/components/MenuBar"
import ContactModal from "@/components/ContactModal"
import ProjectsPane from "@/components/ProjectsPane"
import {mapGetters} from "vuex"
import MobileHeader from "@/components/MobileHeader"
import StagesPane from "@/components/StagesPane"
import FloatingContactToggle from "@/components/FloatingContactToggle"
import ShareDialog from "@/components/ShareDialog"
import MobileButtons from "@/components/MobileButtons"
import FloatingWhatsappButton from "@/components/FloatingWhatsappButton"
import CloseBtn from "@/components/buttons/CloseBtn"
import ImageCanvas from "@/components/ImageCanvas"
import AccessibilityModal from "@/components/AccessibilityModal"
import SideMenu from "@/components/SideMenu"
import {GDialogRoot} from "gitart-vue-dialog";
import AppBg from "@/components/AppBg.vue";
import LottieLoader from "@/components/LottieLoader.vue";

export default {
name: 'App',
components: {
            LottieLoader,
            AppBg,
            GDialogRoot,
            SideMenu,
            AccessibilityModal,
    ImageCanvas,
    CloseBtn,
    FloatingWhatsappButton,
    MobileButtons,
    FloatingContactToggle,
    StagesPane,
    MobileHeader,
    ProjectsPane,
    ContactModal,
    MenuBar,
    ShareDialog
},
computed: {
            contentHeight(){
                    if(this.isMobile && !this.apartmentOnly){
                            return `calc(100vh - (${this.bottomBarHeight}px + ${this.mobileHeaderHeight}px + ${this.mobileButtonsHeight}px))`
                    }else if(this.isSideMenu){
                            return '100vh';
                    }
                    return `calc(100vh - ${this.bottomBarHeight}px)`
            },
            inAdminPath(){
                return this.$route.path.includes('fp-admin')
            },
    ...mapGetters([
                    'isMobile',
                    'isTablet',
                    'isDesktop',
                    'ctrl360',
                    'stagesPaneShow',
                    'mobileMenuOpen',
                    'projectsPaneOpen',
                    'settings',
                    'imageCanvas',
                    'apartmentOnly',
                    'isSideMenu',
                    'hasBottomBar',
                    'mobileHeaderHeight',
                    'mobileButtonsHeight',
                    'bottomBarHeight',
                    'mobileContactFormButtonLocation',
                    'hasWhatsappButton',
                    'showFloatingContactButton',
                    'showHome3DModel'
            ])
},
methods: {
    mobileMediaQuery(e){
        this.$store.dispatch('updateMediaQuery', {mobile: e.matches})
    },
    tabletMediaQuery(e){
        this.$store.dispatch('updateMediaQuery', {tablet: e.matches})
    },
    lowResLaptopMediaQuery(e){
      this.$store.dispatch('updateMediaQuery', {lowResLaptop: e.matches})
    },
    closeMobileMenu(){
        if(this.isMobile && this.mobileMenuOpen){
            this.$store.commit('mobileMenuOpen', false)
        }
        if(this.projectsPaneOpen){
            this.$store.commit('projectsPaneOpen', false)
        }
    }
},
mounted() {
            console.log('standard app')
            //const id = this.$router.params.projectId ? parseInt(`${this.$route.params.projectId}`, 10) : null
    const urlParts = window.location.href.split("#");
    if(urlParts[1] && urlParts[1] !== "/"){
        this.$store.commit('mainInit')
    }

            if(this.settings && this.settings.primary_color){
                    this.$store.dispatch('setPrimaryColorAndShades', this.settings.primary_color)
            }

            this.$store.dispatch('preloadAll')
    /*this.$store.dispatch('loadProjects').then(() => {
                    this.$store.dispatch('loadSettings')
    })*/
    //configure media queries
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)')
    mobileMediaQuery.addEventListener('change',this.mobileMediaQuery)
    this.mobileMediaQuery(mobileMediaQuery)

    const tabletMediaQuery = window.matchMedia('(max-width: 992px) and (min-width: 768px)')
    tabletMediaQuery.addEventListener('change',this.tabletMediaQuery)
    this.tabletMediaQuery(tabletMediaQuery)

    const lowResLaptopMediaQuery = window.matchMedia('(min-width: 993px) and (max-height: 920px) and (min-height: 600px)')
    lowResLaptopMediaQuery.addEventListener('change', this.lowResLaptopMediaQuery)
    this.lowResLaptopMediaQuery(lowResLaptopMediaQuery)
},
async beforeCreate() {
            try{
                    await this.$store.dispatch('validateToken')
            }catch (e){
                    console.warn(e)
            }
}
}
</script>
<style lang="scss">
</style>
