<template>
    <div class="image-canvas">
        <div class="controls-bar">
            <div class="control">
                <fp-icon-button
										animated="artist-painting-color-pallette"
										@click="toggleColorActions"
								>
                    {{$t('צבע')}}
                </fp-icon-button>
                <div class="actions" :class="{open: controlActions === 'color'}">
                    <button
												v-for="(color, colorIndex) in controls.colors"
												:key="colorIndex"
                        class="color-pick"
                        :class="{active: drawColor === color}"
                        @click="drawColor = color"
                    >
                        <span class="circle" :style="{backgroundColor: color}"></span>
                    </button>
                    <!--<button class="color-pick picker">
                        <fp-icon class="circle" symbol="color-picker"></fp-icon>
                    </button>-->
                </div>
            </div>
            <div class="control">
                <fp-icon-button
										animated="erase"
										@click="resetImage"
								>
                    {{$t('ניקוי')}}
                </fp-icon-button>
            </div>
            <!--<div class="control">
                <fp-icon-button symbol="text">
                    {{$t('טקסט')}}
                </fp-icon-button>
            </div>-->
            <div class="control" :class="{active: stageTool === 'drawing'}">
                <fp-icon-button
										animated="edit"
										state="hover-2"
										@click="stageToolChange('drawing')"
								>
                    {{$t('עט')}}
                </fp-icon-button>
            </div>
            <div class="control" :class="{active: stageTool === 'move'}">
                <fp-icon-button
										animated="navigate-three-fingers"
										@click="stageToolChange('move')"
								>
                    {{$t('הזזה')}}
                </fp-icon-button>
            </div>
            <!--<div class="control">
                <fp-icon-button symbol="smiley">
                    {{$t('אייקונים')}}
                </fp-icon-button>
            </div>-->
        </div>
        <div class="editor" ref="editor">
            <!--<canvas ref="editor"></canvas>-->
        </div>
        <div class="download-share">
						<fp-icon-button
								class="btn btn-secondary-outline"
								animated="share2"
								@click="share"
						>
								{{$t('שיתוף')}}
						</fp-icon-button>
            <fp-icon-button
								class="btn btn-secondary-outline"
								animated="download"
								@click="download"
						>
                {{$t('הורדה')}}
            </fp-icon-button>
        </div>
    </div>
</template>

<script>
import FpIconButton from "@/components/buttons/FpIconButton";
import Konva from "konva";

export default {
    name: "ImageCanvas",
    components: {FpIconButton},
    props: {
        imageSrc: {
            required: true
        }
    },
		computed: {
				currentUrl(){
						return window.location.href
				}
		},
    data(){
        return {
            context: null,
            canvas: null,
            isDrawing: false,
            isMoving: false,
            zoom: 1,
            imagePosition: {
                x: null,
                y: null
            },
            lastImagePosition: {
                x: null,
                y: null
            },
            drawColor: '#000',
            controls: {
                colors: [
                    '#000000',
                    '#ffffff',
                    '#E50000',
                    '#FFF500',
                    '#04B800',
                    '#00AEE4'
                ]
            },
            controlActions: null,
            image: null,
            stage: null,
            layer: null,
            groups: {
                image: null,
                drawings: null,
                text: null
            },
            stageTool: null
        }
    },
    methods: {
        init(){
            // load canvas and context
            this.canvas = this.$refs.editor
            //this.context = this.canvas.getContext('2d')
            //this.context.crossorigin = "Anonymous"

            // change canvas size to fit parent
            /*const rect = this.canvas.parentNode.getBoundingClientRect();
            this.canvas.width = rect.width;
            this.canvas.height = rect.height;*/

            /*// load image
            this.drawImage()

            // start mouse drawing
            //this.initDraw()

            this.initMouseWheelZoom()*/
            this.konvaInit()
        },
        stageToolChange(mode){
            this.stageTool = mode
            this.stage.off('mouseup touchend')
            this.stage.off('mousedown touchstart')
            this.stage.off('mousemove touchmove')
            this.stage.off('touchmove')
            this.stage.off('touchend')
            this.groups.image.setAttrs({
                draggable: false
            })
            this.groups.drawings.setAttrs({
                draggable: false
            })

            switch (mode){
                case 'drawing':
                    this.kDrawingInit()
                    break
                case 'move':
                    this.groups.image.setAttrs({
                        draggable: true
                    })
                    this.groups.drawings.setAttrs({
                        draggable: true
                    })
                    this.kScaleInit()
                    break
            }
        },
        konvaInit(){
            const rect = this.canvas.getBoundingClientRect()
            this.stage = new Konva.Stage({
                container: this.canvas,
                width: rect.width,
                height: rect.height,
            });

            this.layer = new Konva.Layer()
            this.stage.add(this.layer)

            this.groups.image = new Konva.Group()
            this.groups.drawings = new Konva.Group()
            this.layer.add(this.groups.image)
            this.layer.add(this.groups.drawings)

            this.kImageInit()
            this.stageToolChange(this.stageTool)
        },
        kImageInit(){
            const rect = this.canvas.getBoundingClientRect()

            Konva.Image.fromURL(this.imageSrc, (kanvaImage) => {
                this.image = kanvaImage

                const image = kanvaImage.attrs.image;

                const hRatio = rect.width  / image.width;
                const vRatio =  rect.height / image.height;
                const ratio  = Math.min ( hRatio, vRatio );
                const imgWidth = image.width * ratio
                const imgHeight = image.height * ratio
                kanvaImage.setAttrs({
                    x: (rect.width - imgWidth) / 2,
                    y: (rect.height - imgHeight) / 2,
                    width: imgWidth,
                    height: imgHeight
                });
                this.groups.image.add(kanvaImage);
            });
        },
        kDrawingInit(){
            let isPaint = false;
            let mode = 'brush';
            let lastLine;

            this.stage.on('mousedown touchstart', () => {
                isPaint = true;
                const pos = this.stage.getPointerPosition();
                lastLine = new Konva.Line({
                    stroke: this.drawColor,
                    strokeWidth: 5,
                    globalCompositeOperation:
                        mode === 'brush' ? 'source-over' : 'destination-out',
                    // round cap for smoother lines
                    lineCap: 'round',
                    // add point twice, so we have some drawings even on a simple click
                    points: [pos.x, pos.y, pos.x, pos.y],
                });
                this.groups.drawings.add(lastLine);
            });

            this.stage.on('mouseup touchend', () => {
                isPaint = false;
            });

            // and core function - drawing
            this.stage.on('mousemove touchmove', (e) => {
                if (!isPaint) {
                    return;
                }

                // prevent scrolling on touch devices
                e.evt.preventDefault();

                const pos = this.stage.getPointerPosition();
                const newPoints = lastLine.points().concat([pos.x, pos.y]);
                lastLine.points(newPoints);
            });

            /*const select = document.getElementById('tool');
            select.addEventListener('change', function () {
                mode = select.value;
            });*/
        },
        kScaleInit(){
            let lastCenter = null;
            let lastDist = 0;

            const getDistance = (p1, p2) => {
                return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
            }

            const getCenter = (p1, p2) => {
                return {
                    x: (p1.x + p2.x) / 2,
                    y: (p1.y + p2.y) / 2,
                };
            }

            const scaleBy = 1.20;
            this.stage.on('wheel', (e) => {
                // stop default scrolling
                e.evt.preventDefault();

                const oldScale = this.groups.image.scaleX();
                const pointer = this.stage.getPointerPosition();

                const mousePointTo = {
                    x: (pointer.x - this.groups.image.x()) / oldScale,
                    y: (pointer.y - this.groups.image.y()) / oldScale,
                };

                // how to scale? Zoom in? Or zoom out?
                let direction = e.evt.deltaY > 0 ? 1 : -1;

                // when we zoom on trackpad, e.evt.ctrlKey is true
                // in that case lets revert direction
                if (e.evt.ctrlKey) {
                    direction = -direction;
                }

                const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

                this.groups.image.scale({ x: newScale, y: newScale });

                const newPos = {
                    x: pointer.x - mousePointTo.x * newScale,
                    y: pointer.y - mousePointTo.y * newScale,
                };
                this.groups.image.position(newPos);
            });

            this.stage.on('touchmove', function (e) {
                e.evt.preventDefault();
                const touch1 = e.evt.touches[0];
                const touch2 = e.evt.touches[1];

                if (touch1 && touch2) {
                    // if the stage was under Konva's drag&drop
                    // we need to stop it, and implement our own pan logic with two pointers
                    if (this.groups.image.isDragging()) {
                        this.groups.image.stopDrag();
                    }

                    const p1 = {
                        x: touch1.clientX,
                        y: touch1.clientY,
                    };
                    const p2 = {
                        x: touch2.clientX,
                        y: touch2.clientY,
                    };

                    if (!lastCenter) {
                        lastCenter = getCenter(p1, p2);
                        return;
                    }
                    const newCenter = getCenter(p1, p2);

                    const dist = getDistance(p1, p2);

                    if (!lastDist) {
                        lastDist = dist;
                    }

                    // local coordinates of center point
                    const pointTo = {
                        x: (newCenter.x - this.groups.image.x()) / this.groups.image.scaleX(),
                        y: (newCenter.y - this.groups.image.y()) / this.groups.image.scaleX(),
                    };

                    const scale = this.groups.image.scaleX() * (dist / lastDist);

                    this.groups.image.scaleX(scale);
                    this.groups.image.scaleY(scale);

                    // calculate new position of the this.groups.image
                    const dx = newCenter.x - lastCenter.x;
                    const dy = newCenter.y - lastCenter.y;

                    const newPos = {
                        x: newCenter.x - pointTo.x * scale + dx,
                        y: newCenter.y - pointTo.y * scale + dy,
                    };

                    this.groups.image.position(newPos);

                    lastDist = dist;
                    lastCenter = newCenter;
                }
            });

            this.stage.on('touchend', function () {
                lastDist = 0;
                lastCenter = null;
            });
        },
        drawImageScaled(img) {
            const hRatio = this.canvas.width  / img.width;
            const vRatio =  this.canvas.height / img.height;
            const ratio  = Math.min ( hRatio, vRatio );
            const imgWidth = img.width * ratio * this.zoom
            const imgHeight = img.height * ratio * this.zoom
            let centerShift_x = ( this.canvas.width - imgWidth ) / 2;
            let centerShift_y = ( this.canvas.height - imgHeight ) / 2;
            if(this.imagePosition.x !== null){
                centerShift_x = this.imagePosition.x - imgWidth / 2
            }
            if(this.imagePosition.y !== null){
                centerShift_y = this.imagePosition.y - imgHeight / 2
            }
            this.context.clearRect(0,0,this.canvas.width, this.canvas.height);
            this.context.drawImage(img, 0,0, img.width, img.height, centerShift_x,centerShift_y,imgWidth, imgHeight);
        },
        drawImage(src = null){
            if(!src){
                src = this.imageSrc
            }
            if(src){
                const image = new Image()
                image.onload = () => {
                    this.drawImageScaled(image)
                    this.$nextTick(() => {
                        this.href = this.canvas.toDataURL()
                    })
                }
                image.crossOrigin = "Anonymous"
                image.src = src
            }
        },
        initMove(){
            this.canvas.onmousedown = (e) => {
                this.isDrawing = false
                this.isMoving = true
                this.imagePosition.x = e.offsetX
                this.imagePosition.y = e.offsetY
                this.drawImage()
            };

            this.canvas.onmousemove = (e) => {
                if (this.isMoving && e.offsetX && e.offsetY) {
                    this.imagePosition.x = e.offsetX
                    this.imagePosition.y = e.offsetY
                    this.drawImage()
                }
            };

            this.canvas.onmouseup = () => {
                this.lastImagePosition.x = this.imagePosition.x
                this.lastImagePosition.y = this.imagePosition.y
                this.isMoving = false
                this.$nextTick(() => {
                    this.href = this.canvas.toDataURL()
                })
            }

            this.canvas.onmouseout = () => {
                this.lastImagePosition.x = this.imagePosition.x
                this.lastImagePosition.y = this.imagePosition.y
                this.isMoving = false
                this.$nextTick(() => {
                    this.href = this.canvas.toDataURL()
                })
            }
        },
        toggleColorActions(){
            if(this.controlActions === 'color'){
                this.controlActions = null
            }else{
                this.controlActions = 'color'
            }
        },
        toggleTextActions(){
            if(this.controlActions === 'text'){
                this.controlActions = null
            }else{
                this.controlActions = 'text'
            }
        },
        initDraw() {
            this.canvas.onmousedown = (e) => {
                this.isDrawing = true
                this.context.beginPath()
                this.context.lineWidth = 5
                this.context.strokeStyle = this.drawColor
                this.context.lineJoin = "round"
                this.context.lineCap = "round"
                this.context.moveTo(e.offsetX, e.offsetY)
            };

            this.canvas.onmousemove = (e) => {
                if (this.isDrawing) {
                    this.context.lineTo(e.offsetX, e.offsetY)
                    this.context.stroke()
                }
            };

            this.canvas.onmouseup = () => {
                this.isDrawing = false
                this.context.closePath()
                this.$nextTick(() => {
                    this.href = this.canvas.toDataURL()
                })
            }

            this.canvas.onmouseout = () => {
                this.isDrawing = false
                this.context.closePath()
                this.$nextTick(() => {
                    this.href = this.canvas.toDataURL()
                })
            }
        },
        initMouseWheelZoom(){
            this.canvas.onmousewheel = (e) => {
                if(e.wheelDeltaY < 0){
                    this.zoomOut()
                }else{
                    this.zoomIn()
                }
            }
        },
        clearCanvas(){
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
        },
        zoomIn(){
            this.zoom += .1
            this.drawImage()
        },
        zoomOut(){
            this.zoom -= .1
            this.drawImage()
        },
        download(){
            const downloadURI = (uri, name) => {
                const link = document.createElement('a')
                link.download = name
                link.href = uri
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }
            const dataURL = this.stage.toDataURL({ pixelRatio: 1 })
            downloadURI(dataURL, 'image.png')
        },
        resetImage(){
            this.stageToolChange(null)
            if(confirm(this.$t('בטוח שברצונך לנקות הכל?'))){
                this.konvaInit()
            }
        },
				async share(){
						if(typeof(navigator) === 'object' && typeof(navigator.share) === 'function'){
								const dataURL = this.stage.toDataURL({ pixelRatio: 1 })
								const blob = await (await fetch(dataURL)).blob();
								const file = new File([blob], 'floorplan.png', { type: blob.type });
								if(navigator.canShare({files: [file]})){
										await navigator.share({
												files: [file],
										})
								}
						}
				},
    },
    mounted() {
        this.init()
				this.$nextTick(() => {
						this.stageToolChange('move')
				})
    }
}
</script>

<style scoped lang="scss">
.image-canvas {
    display: flex;
    flex-flow: column;
    height: 100%;
		position: relative;

    .editor {
        flex-grow: 1;
    }
}

.controls {
    display: block;
}

.image-canvas,
canvas {
    width: 100%;
    flex-grow: 1;
}
.canvas-control-btn {
    background-color: #fff;
    color: #000;
    display: inline-block;
    padding: 5px 10px;
    text-align: center;
    font-size: 0.875rem;
}

.download-share {
    display: flex;
    flex-flow: row-reverse;
    margin: 15px;
		position: absolute;
		bottom: 0;
		left: 0;
		gap: 10px;

    .btn.btn-secondary-outline {
        border-radius: 5px;
    }
}

.controls-bar {
    display: flex;
		position: absolute;
		top: 0;
		right: 50%;
		z-index: 10;
		transform: translateX(50%);
		gap: 5px;

    .control {

        > :deep(button) {
						background-color: #000;
            height: 66px;
            width: 66px;
            font-size: 13px;
            display: inline-flex;
            flex-flow: column;
            gap: 3px;
            white-space: nowrap;
						border: solid 1px var(--primary-light);
						color: var(--primary-light);
						border-radius: 4px;

            svg {
                width: 30px;
                height: 30px;
            }
        }

				&.active {
						> :deep(button) {
							background-image: linear-gradient(180deg, rgba(179, 179, 179, 0.2) 0%, rgba(179, 179, 179, 0) 128.09%);
						}
				}

        .actions {
            position: absolute;
            top: 100%;
            display: none;
            flex-flow: row nowrap;

            &.open {
                display: flex;
            }
        }

        .color-pick {
            height: 35px;
            width: 35px;
            background-color: #242424bf;

            .circle {
                width: 16px;
                height: 16px;
                display: inline-block;
                border-radius: 16px;
                background-color: #fff;
                vertical-align: middle;
            }

            .pf-icon.circle {
                padding: 2px;
                width: 21px;
                height: 21px;
                box-sizing: border-box;
            }
        }
    }


}
</style>
