<template>
	<nav
    class="side-menu"
    ref="sideMenu"
    :class="{ 'show-titles': showTitles }"
		@click="toggleTitles"
    @mouseenter="toggleTitles"
    @mouseleave="toggleTitles"
	>
		<div class="top-area">
			<span id="wsnavtoggle" class="wsanimated-arrow ml-4 ">
				<span />
			</span>
			<main-logo></main-logo>
			<transition>
				<language-switcher></language-switcher>
			</transition>
		</div>
		<div class="middle-menu">
			<menu-bar-middle :show-titles="showTitles"></menu-bar-middle>

		</div>
		<!-- <button v-if="isTouchScreen" type="button" @click.stop="toggleTitles">
			<fp-icon v-if="showTitles" symbol="arrow-right"></fp-icon>
			<fp-icon v-else symbol="arrow-left"></fp-icon>
		</button> -->
		<div class="bottom-area">
			<transition>
				<fp-icon-button v-if="showTitles" @click.stop="toggleContactModal" class="btn btn-secondary-outline"
					symbol="edit-03" :iconBackGroundColor="'white'" :iconColor="'#6149F9'"
					:title="$t('יצירת קשר')">

					<span>{{ $t('יצירת קשר') }}</span>
				</fp-icon-button>
				<!-- <FpNewIcon v-else iconName="phone" class="circledIcon bgGreen" size="1rem" @click="toggleContactModal"/> -->
				<!-- <fp-icon-button v-else class="contact-btn" :class="{ 'has-title': showTitles }" animated="call-phone"
					state="hover-phone" @click="toggleContactModal" symbol-size="32"></fp-icon-button> -->
			</transition>
			<menu-bar-left v-if="showTitles && !isMobile" v-stop-propagation></menu-bar-left>
		</div>
	</nav>
	<div>
		<div v-if="showBackButton || showContactIcons" class="contactIcons" >
			<div v-if="showContactIcons">
				<tel-link v-if="contact_details.phone" :tel="contact_details.phone">
					<div class="circledIcon bgPurple" >
						<!-- <fp-icon symbol="phone" :size="15" ></fp-icon> -->
						<FpNewIcon iconName="phone" class="circledIcon " size="0.9rem"	/>
					
					</div>
				</tel-link>
				<br>
				<!-- <tel-link v-if="contact_details.whatsapp && contact_details.whatsapp.phone" type="whatsapp" -->
				<tel-link v-if="contact_details.whatsapp && contact_details.whatsapp.phone" type="whatsapp"
					:tel="contact_details.whatsapp.phone" :message="contact_details.whatsapp.message">
					<div class="circledIcon bgWhite">
						<FpNewIcon iconName="whatsapp" class="circledIcon  stroke-green" size="0.9rem"	/>
						
					</div>
				</tel-link>
			</div>
			<div v-if="showBackButton">
				<FpNewIcon iconName="caretCircled" class="circledIcon backButton " size="2rem" @click.stop="goBack"/>
			</div>
			<!-- <div v-if="showFavsButton">
				<apartment-fav-button v-if="showFavsButton" :apt-id="apartment.id"></apartment-fav-button>
			</div> -->
		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MenuBarLeft from "@/components/MenuBarLeft";
import MainLogo from "@/components/MainLogo";
import FpIconButton from "@/components/buttons/FpIconButton";
import MenuBarMiddle from "@/components/MenuBarMiddle.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import TelLink from "@/components/TelLink";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';

const showBackButtonPages = ["apartments-compare", "favorite-apartments", 'privacy', 'apartment']
export default {
	name: "SideMenu",
	components: {
		LanguageSwitcher,
		MenuBarMiddle,
		FpIconButton,
		MainLogo,
		MenuBarLeft,
		// FpIcon,
		TelLink,
		FpNewIcon,
		
		// ApartmentFavButton
	},
	computed: {
		showTitles: {
			get() {
				return this.sideMenuShowTitles
			},
			set(value) {
				this.setSideMenuTitles(value)
			}
		},
		contact_details(){
	
			if(this.settings && this.settings.contact_form && this.settings.contact_form.contact_details){
				console.log("this.settings.contact_form.contact_details", this.settings.contact_form.contact_details)
				return this.settings.contact_form.contact_details
			}
			return null
		},
    showContactIcons(){
      return this.$route.path === '/'
    },
    showBackButton(){
      return showBackButtonPages.includes(this.$route.name);
    },
		...mapGetters([
			'settings',
			'isMobile',
			'mobileMenuOpen',
			'projectsPaneOpen',
			'currentProjectId',
			'isTouchScreen',
			'locale',
			'sideMenuShowTitles',
      'sideMenuToggleMethod'
			// 'showContactButtons',
			// 'showFavsButton'
		]),
		...mapGetters({
			projectId: 'currentProjectId'
		})
	},
	watch: {
		locale(newVal, oldVal) {
			if (newVal !== oldVal) {
				this.hoverHideTitles()
			}
		}
	},
	methods: {
		closeMobileMenu() {
			
			if (this.isMobile && this.mobileMenuOpen) {
				this.$store.commit('mobileMenuOpen', false)
			}
			if (this.projectsPaneOpen) {
				this.$store.commit('projectsPaneOpen', false)
			}
		},
		toggleTitles(event) {
    //   console.log('event type', event.type, this.sideMenuToggleMethod);
      if (event.type === 'click' && this.sideMenuToggleMethod === 'click') {
        this.showTitles = !this.showTitles;
      } else if(this.sideMenuToggleMethod === 'mouse-hover') {
        this.showTitles = !this.showTitles;
      }
			// this.showTitles = !this.showTitles;
		},
		hoverHideTitles() {
			if (!this.isTouchScreen) {
				this.showTitles = false;
			}
		},
		hoverShowTitles() {
			if (!this.isTouchScreen) {
				this.showTitles = true;
			}
		},
		goBack() {
			this.$router.go(-1);
			// or
			// window.history.back();
		},
		toggleContactModal() {
			this.$store.commit('toggleContactModal')
		},
		...mapMutations({
			setSideMenuTitles: 'sideMenuShowTitles'
		})
	}
}
</script>

<style scoped lang="scss">
nav.side-menu {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.00) 100%), rgba(28, 28, 28, 0.90);
	backdrop-filter: blur(5px);
	height: 100%;
	padding: 10px 15px;
	width: 42px;
	font-size: 1.125rem;
	box-sizing: content-box;
	transition-property: width;
	transition-duration: .4s;
	transition-timing-function: ease;
	
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: flex-start;
	text-align: center;
	position: relative;
	z-index: 13;
	&.show-titles {
		width: 222px;
		background-color: transparent;
		background-image: linear-gradient(to left, rgb(0 0 0 / 88%) 20%, rgb(0 0 0 / 68%) 50%);

		.wsanimated-arrow {
			/* left: 240px; */
		}

		.wsanimated-arrow span:after {
			width: 19px;
		}

		.wsanimated-arrow span {
			background-color: transparent;
		}

		.wsanimated-arrow span:before {
			top: -7px;
		}

		.wsanimated-arrow span:before {

			transform: rotate(-135deg);
			-moz-transform: rotate(-135deg);
			-ms-transform: rotate(-135deg);
			-o-transform: rotate(-135deg);
			-webkit-transform: rotate(-135deg);

		}

		.wsanimated-arrow span:after {
			transform: rotate(135deg);
			-moz-transform: rotate(135deg);
			-ms-transform: rotate(135deg);
			-o-transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
		}
	}


	.wsanimated-arrow {
		width: 2rem;
		height: 2rem;
		z-index: 10;
		-webkit-transition: all 0.4s ease-in-out;
		-moz-transition: all 0.4s ease-in-out;
		-o-transition: all 0.4s ease-in-out;
		-ms-transition: all 0.4s ease-in-out;
		transition: all 0.4s ease-in-out;
		cursor: pointer;
		padding: 16px 10px 16px 0px;
		margin: 5px 0 0 15px;
	}

	.wsanimated-arrow span,
	.wsanimated-arrow span:before,
	.wsanimated-arrow span:after {
		cursor: pointer;
		height: 2px;
		width: 13px;
		background: white;
		position: absolute;
		display: block;
		transition: all 500ms ease-in-out;
		content: '';
	}

	.wsanimated-arrow span:before {
		top: -7px;
		width: 19px;
	}

	.wsanimated-arrow span:after {
		bottom: -7px;
		width: 19px;
	}



	.top-area {

		text-align: center;
		justify-content: space-between;
		display: flex;
		flex-direction: column;
		width: 100%;
		:deep(.main-logo){
			img{
				width: 30vh;
			}
		}
	}

	.bottom-area {

		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;		
		justify-content: space-between;
		padding-bottom: 1.5rem;
		height: 15vh;
		.fp-icon-button {
			width: 10rem;
		}
	}


	.bottom-area {
		ul {
			display: flex;
			flex-flow: column;
			transition: transform .4s ease;
			transform: rotate(0);
			transform-origin: bottom left;

			:deep(button) {
				transition: transform .4s ease;
			}
		}

		:deep(.contact-btn.fp-icon-button) {

			&.has-title {
				border: solid 1px #fff;
				border-radius: 4px;
			}

			&.v-enter-active,
			&.v-leave-active {
				transition-duration: .3s;
				transition-property: transform;
				transition-timing-function: ease;
			}

			&.v-enter-from {
				transform: translateX(320px);
			}

			&.v-leave-to {
				transform: translateX(-320px);
			}

		}
	}

	&.show-titles {
		.bottom-area {
			ul {
				transform: rotate(-90deg) translateY(75%);

				:deep(button) {
					transform: rotate(90deg);
				}
			}
		}
	}

	:deep(ul.middle-menu) {

		/*border-bottom: solid 1px #fff;*/
		li {
			margin-bottom: 1vh;

			/*border-top: solid 1px #fff;*/
			a {
				display: block;

				white-space: nowrap;

				.m-label {
					display: inline-block;
					margin: 0 10px;

					&.v-enter-active,
					&.v-leave-active {
						transition-duration: .3s;
						transition-property: transform;
						transition-timing-function: ease;
					}

					&.v-enter-from,
					&.v-leave-to {
						transform: translateX(320px);
					}
				}
			}

			@for $i from 1 through 10 {

				&:nth-child(#{$i}) {
					.m-label {
						transition-delay: $i * .06s;
					}
				}
			}
		}
	}
}



.contactIcons {
	display: block;
	position: absolute;
	left: -2.5rem;
	top: 1rem;
	z-index: 8;
	svg {
		width: 1rem;

	}
	.backButton{
		rotate: 180deg;
		cursor: pointer;
		background-color: #FFFFFFB2;	
	} 
}

.ltr {

	nav.side-menu {
		.wsanimated-arrow {
			
			transform: translateX(-.3rem);
		}
		:deep(ul.middle-menu) {

			/*border-bottom: solid 1px #fff;*/
			li {
				font-size: .9rem !important;
				/*border-top: solid 1px #fff;*/
				a {
					.m-label {
						
						&.v-enter-from,
						&.v-leave-to {
							transform: translateX(-320px);
						}
					}
				}
			}
		}

		.bottom-area {
			:deep(.contact-btn.fp-icon-button) {
				&.v-enter-from {
					transform: translateX(320px);
				}

				&.v-leave-to {
					transform: translateX(-320px);
				}
			}
		}
	}
	.contactIcons {

		left: auto;
		right: -2.5rem;


		svg {
			width: 1rem;
	
		}
		.backButton{
			rotate: 0deg;
	
		} 
	}
}
.is-mobile{
	.bottom-area {
		display: none;
	}
}
@media only screen and (max-height: 740px) {
	.top-area {

		:deep(.main-logo){
			img{
				width: 5rem !important;
			}
		}
	}
	.middle-menu {
		:deep(ul.middle-menu) {

			/*border-bottom: solid 1px #fff;*/
			li {
				margin-bottom: 0 !important;
			}
		}
	}
}
</style>
