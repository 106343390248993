<template>
  <div class="signature">
    <button @click="clear">{{$t('מחק')}}</button>    
    <canvas ref="canvas" 
            @mousedown="startDrawing" 
            @mousemove="draw" 
            @mouseup="stopDrawing" 
            @mouseleave="stopDrawing"
            @touchstart="startDrawing" 
            @touchmove="draw" 
            @touchend="stopDrawing"></canvas>
  </div>
</template>

<script>
export default {
  name: "SignatureCanvas",
  data() {
    return {
      drawing: false,
      lastX: 0,
      lastY: 0,
    };
  },
  mounted() {
    this.setupCanvas();
  },
  methods: {
    setupCanvas() {
      const canvas = this.$refs.canvas;
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    },
    getEventPosition(event) {
      if (event.touches && event.touches.length > 0) {
        const rect = event.target.getBoundingClientRect();
        return { 
          x: event.touches[0].clientX - rect.left, 
          y: event.touches[0].clientY - rect.top 
        };
      }
      return { x: event.offsetX, y: event.offsetY };
    },
    startDrawing(event) {
      
      this.drawing = true;
      const { x, y } = this.getEventPosition(event);
      this.lastX = x;
      this.lastY = y;
      event.preventDefault();
    },
    draw(event) {
      if (!this.drawing) return;
      const { x, y } = this.getEventPosition(event);
      const ctx = this.$refs.canvas.getContext("2d");
      ctx.strokeStyle = "#000";
      ctx.lineJoin = "round";
      ctx.lineCap = "round";
      ctx.lineWidth = 3;

      ctx.beginPath();
      ctx.moveTo(this.lastX, this.lastY);
      ctx.lineTo(x, y);
      ctx.stroke();

      this.lastX = x;
      this.lastY = y;
      event.preventDefault();
    },
    stopDrawing() {
      this.drawing = false;
    },
    clear() {
      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    },
    getSignatureData() {
      return this.$refs.canvas.toDataURL();
    },
  },
};
</script>
  
  <style scoped lang="scss">
    .signature{
        position: relative;
        width: 100%;
        height: 100%;
        button {
            position: absolute;
            left: 0;
            top: -1.5rem;
            
            color:white;
            padding: .3rem;
        }
    }
    canvas {
        border: 1px solid black;
        background-color: white;
        border-radius: 0.3125rem;
        width: 100%;
        height: 100%;

    }
    .sendButtonWrapper{
        position: relative;
        width: 10rem;
        height: 10rem;
        
    }
    .sendButtonWrapper span{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        
    }
  </style>
  