<template>
	<div class="mobile-header flex justify-between" v-if="isMobile" ref="mobileHeader">

		<FpIconsDropDown mainIconName="fpPen" :options="['fpPen']"
		:onChange="option => handleIconsSelect(option)">
			<tel-link v-if="contact_details.phone" :tel="contact_details.phone">
				<FpNewIcon iconName="phone" class="circledIcon " size="1rem" />
			</tel-link>
			<tel-link v-if="contact_details.whatsapp" type="whatsapp" :tel="contact_details.whatsapp.phone" :message="contact_details.whatsapp.message">
				<FpNewIcon iconName="whatsapp" class="circledIcon " size="1rem" />
			</tel-link>
			<div class="copyWrapper" v-if="showCopyApartmentButton">
				<FpNewIcon iconName="copy" class="circledIcon" size="1rem" @click.stop="copyApartmentOnly"/>
				<p class="clipboard-msg" v-if="urlCopySuccess">{{$t('כתובת הועתקה ללוח')}}</p>
				

			</div>
		</FpIconsDropDown>
		<main-logo></main-logo>
		<div class="flex justify-between">
			<language-switcher></language-switcher>
			<FpNewIcon v-if="showBackButton" iconName="caretCircled" class="circledIcon backButton" size="2rem" @click.stop="goBack"/>
			<FpIconsDropDown v-else :options="['shareButton2', 'accessibility', 'privacy']"
				:onChange="option => handleIconsSelect(option)" />
			
		</div>

	</div>
</template>

<script>
// import MainLogo from "@/components/MainLogo";
// import FpIconButton from "@/components/buttons/FpIconButton";
import { mapGetters, mapState } from "vuex";
import MainLogo from "@/components/MainLogo";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import FpNewIcon from '@/components/buttons/FpNewIcon.vue';
import FpIconsDropDown from '@/components/buttons/FpIconsDropDown.vue';
import copy from 'copy-to-clipboard';
import TelLink from "@/components/TelLink";
// import MenuBarLeft from "@/components/MenuBarLeft";
// import CloseBtn from "@/components/buttons/CloseBtn";
// import MenuBarMiddle from "@/components/MenuBarMiddle.vue";
const showBackButtonPages = ['apartments-compare', 'apartment']
export default {
	name: "MobileHeader",
	components: { LanguageSwitcher, MainLogo, FpNewIcon, FpIconsDropDown, TelLink },
	computed: {
		contact_details() {

			if (this.settings && this.settings.contact_form && this.settings.contact_form.contact_details) {
				// console.log("this.settings.contact_form.contact_details", this.settings.contact_form.contact_details)
				return this.settings.contact_form.contact_details
			}
			return null
		},
		shareUrl() {
			const baseUrl = `${window.location.protocol}//${window.location.host}`
			return `${baseUrl}/projects/${this.currentProjectId}/apartments/${this.currentProductId}`
		},
		...mapGetters(['isMobile', 'mobileMenuOpen', 'mobileContactFormButtonLocation', 'settings']),
		...mapState(['currentProductId', 'currentProjectId']),
	},
	data() {
		return {
			showBackButton: false,
			showCopyApartmentButton: false,
			urlCopySuccess: false
		}
	},
	watch: {
		isMobile() {
			this.updateHeight()
		},
		$route(to) {
			this.showBackButton = showBackButtonPages.includes(to.name)
			this.showCopyApartmentButton = to.name == 'apartment'
		}
	},
	methods: {
		menuClick() {
			setTimeout(() => {
				this.$store.commit('mobileMenuOpen', false)
			}, 100)
		},
		toggleMenu() {
			this.$store.dispatch('toggleMobileMenu')
		},
		copyApartmentOnly() {
			
			this.urlCopySuccess = copy(`${this.shareUrl}/ao`)
			setTimeout(() => {
				this.urlCopySuccess = false
			}, 2000)
		},
		goBack() {
			this.$router.go(-1);
			// or
			// window.history.back();
		},
		handleIconsSelect(option) {
			switch (option) {
				case 'privacy':
					this.$router.push('/projects/privacy')
					break;
				case 'shareButton2':
					this.copyToClipboard()
					break;
				case 'accessibility':
					this.$store.commit('toggleAccessibilityModal', true)
					break;
				case 'fpPen':
					// console.log('toggleContactModal')
					this.$store.commit('toggleContactModal')
					break;
				default:
					break



			}
		},
		copyToClipboard() {
			const baseUrl = `${window.location.protocol}//${window.location.host}`
			this.urlCopySuccess = copy(baseUrl)
			setTimeout(() => {
				this.urlCopySuccess = false
			}, 2000)
		},
		updateHeight() {
			this.$nextTick(() => {
				if (this.$refs.mobileHeader) {
					this.$store.commit('mobileHeaderHeight', this.$refs.mobileHeader.clientHeight)
				}
			})
		}
	},
	mounted() {

		this.updateHeight()
	}
}
</script>

<style scoped lang="scss">
.mobile-header {
	position: relative;
	z-index: 25;
	background-color: black;
	padding: 0 .7rem;
	//background: linear-gradient(180deg, #000 30%, rgba(0, 0, 0, 0.00) 100%);
	height: 4rem;
	align-items: center;

	.mobile-header-content {
		height: 57px;

		display: flex;
		align-items: center;
		padding: 0 20px;
	}

	#mobileHeaderBottom {
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		width: 100vw;

		:deep(.apt-buttons) {
			display: flex;
			overflow-x: auto;
			overflow-y: hidden;
			gap: 15px;
			background-color: #000;
			padding: 0;

			button {
				margin-bottom: .5rem;
			}
		}

		:deep(nav.footer-tabs) {
			background-color: #000;
			display: flex;
			gap: 15px;
			padding-bottom: 9px;
			z-index: 10;
			overflow-x: auto;
			margin-right: 0;
			margin-left: 0;

			.btn {
				min-width: 0;
				padding-right: 15px;
				padding-left: 15px;
				background-color: #000;
				flex-shrink: 0;

				&:first-child {
					margin-right: auto;
				}

				&:last-child {
					margin-left: auto;
				}
			}
		}
	}
	.copyWrapper {
		position: relative;
		.clipboard-msg {
			position: fixed;
			right: 3.5rem;
			top: 8.5rem;
		}

	}
	.backButton {
		margin-right: .3rem;
		background-color: var(--primary);
		color: white;
	}
	.mobile-menu {
		position: absolute;
		top: 0;
		z-index: 10;
		background-color: #000;
		border-left: solid 1px #919698;
		width: 260px;
		max-width: 100%;
		right: 0;
		border-radius: 0 0 0 5px;
		padding: 50px 10px 30px;
		border-bottom: solid 1px #919698;

		:deep(.close-btn) {
			position: absolute;
			top: 15px;
			right: 15px;
		}

		:deep(ul) li {
			margin-bottom: 10px;
		}

		:deep(ul) li button,
		:deep(ul) li a {
			display: flex;
			padding: 0;
			gap: 5px;
			line-height: 1;
			font-size: 1rem;
			align-items: center;

			&:hover,
			&:focus {
				background-color: #242424;
				border-left-color: #01EADD;
			}

			>svg {
				width: 32px;
				height: 25px;
			}
		}
	}

	:deep(.main-logo) {
		margin-right: auto;
		margin-left: auto;
	}
}
</style>
