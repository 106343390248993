<template>
	<span class="illustration-only-disclaimer" v-if="enabled" :style="style">{{text}}</span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
		name: "IllustrationOnlyDisclaimer",
		computed: {
				style(){
						return {
								color: this.color,
								backgroundColor: this.bgColor,
								top: this.posTop,
								bottom: this.posBottom,
								left: this.posLeft,
								right: this.posRight,
								transform: this.transformTranslateY
						}
				},
				color(){
						if(this.galleriesSettings && this.galleriesSettings.illustration_only_disclaimer_label_font_color){
								return this.galleriesSettings.illustration_only_disclaimer_label_font_color
						}
						return '#ffffff'
				},
				bgColor(){
						if(this.galleriesSettings && this.galleriesSettings.illustration_only_disclaimer_label_bg_color){
								return this.galleriesSettings.illustration_only_disclaimer_label_bg_color
						}
						return '#000000'
				},
				pos(){
						if(this.galleriesSettings && this.galleriesSettings.illustration_only_disclaimer_label_position){
								return this.galleriesSettings.illustration_only_disclaimer_label_position
						}
						return 'top-left'
				},
				transformTranslateY(){
						if(this.pos.indexOf('middle') !== -1){
								return 'translateY(-50%)'
						}
						return 'none'
				},
				posTop(){
						if(this.pos.indexOf('top') !== -1){
								return '0'
						}
						if(this.pos.indexOf('middle') !== -1){
								return '50%'
						}
						return 'auto'
				},
				posBottom(){
						if(this.pos.indexOf('bottom') !== -1){
								return '0'
						}
						return 'auto'
				},
				posLeft(){
						if(this.pos.indexOf('left') !== -1){
								return '0'
						}
						return 'auto'
				},
				posRight(){
						if(this.pos.indexOf('right') !== -1){
								return '0'
						}
						return 'auto'
				},
				text(){
						if(this.galleriesSettings && this.galleriesSettings.illustration_only_disclaimer_label){
								return this.galleriesSettings.illustration_only_disclaimer_label
						}
						return this.$t('התמונה להמחשה בלבד')
				},
				enabled(){
						return this.galleriesSettings && this.galleriesSettings.illustration_only_disclaimer_label_enabled
				},
				...mapGetters(['galleriesSettings'])
		}
}
</script>

<style scoped>
	.illustration-only-disclaimer {
			font-size: 0.625rem;
			background-color: #000;
			color: #fff;
			display: inline-block;
			padding: 5px;
	}
</style>
