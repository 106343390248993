
<template>
    <div>
      {{ $t(message) }}
    </div>
  </template>
  
  <script>

  
  export default {
    name: "TimeBasedMessage",
    data() {
      return {
        message: ""
      };
    },
    mounted() {
      this.setMessage();
    },
    methods: {
      setMessage() {
        const currentHour = new Date().getHours();
  
        if (currentHour >= 4 && currentHour < 12) {
          this.message = "בוקר טוב";
        } else if (currentHour >= 12 && currentHour < 17) {
          this.message = "צהריים טובים";
        } else if (currentHour >= 17 && currentHour < 21) {
          this.message = "ערב טוב";
        } else {
          this.message = "לילה טוב";
        }
      }
    }
  };
  </script>
  